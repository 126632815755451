import { flattenDeep } from 'lodash';

import * as accounts from './resources/accounts';
import * as accountTeamMembers from './resources/accountTeamMembers';
import * as articles from './resources/articles';
import * as authenticatorDevices from './resources/authenticatorDevices';
import * as cashFlowEvents from './resources/cashFlowEvents';
import * as clients from './resources/clients';
import * as costReports from './resources/costReports';
import * as documents from './resources/documents';
import * as esgData from './resources/esgData';
import * as events from './resources/events';
import * as expertInput from './resources/expertInput';
import * as family from './resources/family';
import * as feed from './resources/feed';
import * as filterValues from './resources/filterValues';
import * as financialValues from './resources/financialValues';
import * as forms from './resources/forms';
import * as funds from './resources/funds';
import * as goals from './resources/goals';
import * as illiquidCashflow from './resources/illiquidCashflow';
import * as illiquidSecurities from './resources/illiquidSecurities';
import * as investmentPreferences from './resources/investmentPreferences';
import * as investmentProfile from './resources/investmentProfile';
import * as investmentStrategies from './resources/investmentStrategies';
import * as investmentStrategyAssetAllocations from './resources/investmentStrategyAssetAllocations';
import * as investmentStrategyImplementationPlans from './resources/investmentStrategyImplementationPlans';
import * as investmentStrategyIndexChanges from './resources/investmentStrategyIndexChanges';
import * as investmentStrategyPortfolioCharacteristics from './resources/investmentStrategyPortfolioCharacteristics';
import * as investmentStrategyProducts from './resources/investmentStrategyProducts';
import * as investmentStrategyCosts from './resources/investmentStrategyCosts';
import * as investmentStrategySimulations from './resources/investmentStrategySimulations';
import * as marketValues from './resources/marketValues';
import * as meetings from './resources/meetings';
import * as milestones from './resources/milestones';
import * as network from './resources/network';
import * as offices from './resources/offices';
import * as operationalMessages from './resources/operationalMessages';
import * as organization from './resources/organization';
import * as personalGreeting from './resources/personalGreeting';
import * as podcastEpisodes from './resources/podcastEpisodes';
import * as portfolioIndices from './resources/portfolioIndices';
import * as portfolioLenses from './resources/portfolioLenses';
import * as portfolios from './resources/portfolios';
import * as securities from './resources/securities';
import * as securityPerformance from './resources/securityPerformance';
import * as signingEvents from './resources/signingEvents';
import * as standardFilters from './resources/standardFilters';
import * as strategy from './resources/strategy';
import * as tasks from './resources/tasks';
import * as taxDetails from './resources/taxDetails';
import * as transactions from './resources/transactions';
import * as users from './resources/users';
import * as wealthBalance from './resources/wealthBalance';
import * as webinars from './resources/webinars';

export const sagas = flattenDeep([
  ...accounts.sagas,
  ...accountTeamMembers.sagas,
  ...articles.sagas,
  ...authenticatorDevices.sagas,
  ...cashFlowEvents.sagas,
  ...clients.sagas,
  ...costReports.sagas,
  ...documents.sagas,
  ...esgData.sagas,
  ...events.sagas,
  ...expertInput.sagas,
  ...family.sagas,
  ...feed.sagas,
  ...filterValues.sagas,
  ...financialValues.sagas,
  ...forms.sagas,
  ...funds.sagas,
  ...goals.sagas,
  ...illiquidCashflow.sagas,
  ...illiquidSecurities.sagas,
  ...investmentPreferences.sagas,
  ...investmentProfile.sagas,
  ...investmentStrategies.sagas,
  ...investmentStrategyAssetAllocations.sagas,
  ...investmentStrategyImplementationPlans.sagas,
  ...investmentStrategyIndexChanges.sagas,
  ...investmentStrategyPortfolioCharacteristics.sagas,
  ...investmentStrategyProducts.sagas,
  ...investmentStrategyCosts.sagas,
  ...investmentStrategySimulations.sagas,
  ...marketValues.sagas,
  ...meetings.sagas,
  ...milestones.sagas,
  ...network.sagas,
  ...offices.sagas,
  ...operationalMessages.sagas,
  ...organization.sagas,
  ...personalGreeting.sagas,
  ...podcastEpisodes.sagas,
  ...portfolioIndices.sagas,
  ...portfolioLenses.sagas,
  ...portfolios.sagas,
  ...securities.sagas,
  ...securityPerformance.sagas,
  ...signingEvents.sagas,
  ...standardFilters.sagas,
  ...strategy.sagas,
  ...tasks.sagas,
  ...taxDetails.sagas,
  ...transactions.sagas,
  ...users.sagas,
  ...wealthBalance.sagas,
  ...webinars.sagas,
]);
