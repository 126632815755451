import React, { useState, useEffect } from 'react';
import useCollapse from 'react-collapsed';
import styled, { css } from 'styled-components';

import { easeInOut, shortAnimationTime } from '../../constants/animation';
import { backgroundWhite, hoverBoxShadowLvl2 } from '../../constants/colors';
import { SPACING_24, SPACING_8 } from '../../constants/spacing';

import { BaseListItem } from './BaseListItem';
import { ExpandableArrow } from './ExpandableArrow';

const StyledBaseListItem = styled(BaseListItem)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  transition: box-shadow ${shortAnimationTime} ${easeInOut};

  ${(props) =>
    props.enableHover
      ? css`
          &:hover {
            box-shadow: ${hoverBoxShadowLvl2};
          }
        `
      : null}
`;

const DetailsWrapper = styled.div`
  border-radius: ${SPACING_8};
  background: ${(props) => props.detailsBackgroundColor};
  margin-top: 2px;
`;

export const CollapsibleListItem = (props) => {
  const {
    children,
    details,
    showFlipArrow = true,
    startExpanded = false,
    detailsStyle,
    detailsInnerStyle,
    arrowComponent,
    arrowSize,
    onExpand = () => {},
    expandedStyle,
    padding = SPACING_24,
    detailsBackgroundColor = backgroundWhite,
    detailsExpandedStyle,
    detailsPadding = SPACING_24,
    style,
    enableHover = true,
    ...rest
  } = props;

  const [isExpanded, setIsExpanded] = useState(startExpanded);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
    onExpand(!isExpanded);
  };

  useEffect(() => {
    setIsExpanded(startExpanded);
  }, [startExpanded]);

  return (
    <>
      <StyledBaseListItem
        style={{ ...style, ...(isExpanded ? expandedStyle : {}) }}
        {...getToggleProps({ onClick: toggleExpanded })}
        enableHover={enableHover}
        {...rest}
      >
        {children}
        {showFlipArrow ? (
          <ExpandableArrow
            direction={isExpanded ? 'up' : 'down'}
            arrowComponent={arrowComponent}
            size={arrowSize}
            style={{ position: 'relative', zIndex: 10 }}
          />
        ) : null}
      </StyledBaseListItem>
      <DetailsWrapper
        {...getCollapseProps()}
        style={Object.assign(
          getCollapseProps().style,
          detailsStyle,
          isExpanded ? detailsExpandedStyle : {}
        )}
        className="details"
        detailsBackgroundColor={detailsBackgroundColor}
      >
        <div style={{ paddingBlock: detailsPadding, ...detailsInnerStyle }}>
          {details}
        </div>
      </DetailsWrapper>
    </>
  );
};
