import React from 'react';
import styled from 'styled-components';

import { backgroundMain } from '../../constants/colors';

const StyledDotIcon = styled.div`
  width: ${(props) => props.radius}px;
  height: ${(props) => props.radius}px;
  border-radius: ${(props) => props.radius / 2}px;
  background-color: ${(props) => props.color};
  min-width: ${(props) => props.radius}px;
  min-height: ${(props) => props.radius}px;
`;

export const DotIcon = ({ radius = 10, color = backgroundMain, ...rest }) => {
  return (
    <StyledDotIcon
      radius={radius}
      color={color}
      className="printBackground"
      {...rest}
    />
  );
};
