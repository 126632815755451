import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import { useCookies } from 'react-cookie';
import { browserSupportsWebAuthn } from '@simplewebauthn/browser';

import { config, generateCodeVerifier, t } from '@formue-app/core';

import { ContentWrapper } from '../components/layout';
import {
  backgroundMain,
  backgroundWhite,
  blackTranslucent,
  textMain,
  textLightest,
  accent,
} from '../constants/colors';
import { H4Size, paragraphSmallSize } from '../constants/text';
import { mobileCondition, tabletCondition } from '../constants/media';
import {
  BORDER_RADIUS_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_24,
} from '../constants/spacing';
import { H2, Paragraph, ParagraphSmall } from '../components/texts';

import { UsernameLogin } from '../components/login/UsernameLogin';
import { BankIdLogin } from '../components/login/BankIdLogin';
import { PasskeyLogin } from '../components/login/PasskeyLogin';
import { AppStoreBadge } from '../components/storeBadges/AppStoreBadge';
import { PlayStoreBadge } from '../components/storeBadges/PlayStoreBadge';
import { Logo } from '../components/common/Logo';
import { LanguageSelectorDropDown } from '../components/common/LanguageSelectorDropDown';

import { FullscreenPage } from './base/FullscreenPage';

import background1 from '../assets/images/backgrounds/1.jpg';
import background2 from '../assets/images/backgrounds/2.jpg';
import background3 from '../assets/images/backgrounds/3.jpg';
import background4 from '../assets/images/backgrounds/4.jpg';
import background5 from '../assets/images/backgrounds/5.jpg';
import { OperationalMessages } from '../components/operationalMessages/OperationalMessages';
import { SpriteIcon } from '../components/common/SpriteIcon';
import { ButtonSmall } from '../components/buttons';
import { SpriteIconFunctional } from '../components/common/SpriteIconFunctional';

const backgrounds = [
  background1,
  background2,
  background3,
  background4,
  background5,
];

const getRandomBackground = () => {
  const index = Math.floor(Math.random() * backgrounds.length) + 1 - 1;
  return backgrounds[index];
};

const StyledContentWrapper = styled(ContentWrapper)`
  background-image: linear-gradient(
      ${blackTranslucent(0.15)},
      ${blackTranslucent(0.15)}
    ),
    url(${getRandomBackground()});
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  background-position: center center, center center;
  box-shadow: inset 0 0 12vw 12vw ${blackTranslucent(0.25)};
  justify-content: flex-start;

  padding-top: 120px;
  padding-bottom: 100px;

  @media ${mobileCondition} {
    padding-top: 94px;
    padding-bottom: 120px;
  }
`;

const LoginBox = styled.section`
  background: ${backgroundMain};
  margin: 0 auto;
  padding: 65px 50px 30px 50px;
  box-shadow: 0 0 2px ${blackTranslucent(0.2)};
  border-radius: ${BORDER_RADIUS_LARGE};
  position: relative;
  max-width: 500px;

  @media ${mobileCondition} {
    margin: 0 ${SPACING_16};
    padding: ${SPACING_16} ${SPACING_24};
  }

  @media ${tabletCondition} {
    padding: 50px 50px 5px 50px;
  }
`;

const StyledHeader = styled.header`
  margin-bottom: 20px;

  h1 {
    margin-bottom: 20px;
  }

  svg {
    vertical-align: bottom;
    margin-right: ${SPACING_16};
    position: relative;
  }
`;

const DemoInfo = styled.div`
  margin-top: ${SPACING_24};

  @media ${mobileCondition} {
    margin-top: ${SPACING_16};
  }
`;

const MobileAppInfo = styled.div`
  width: calc(100% + 100px);
  margin: 20px 0 0 -50px;
  font-size: ${H4Size};
  border-top: 1px solid ${accent.sand3};
  padding: 20px 0;
  text-align: center;

  @media ${mobileCondition} {
    width: calc(100% + 50px);
    margin: 20px 0 0 -25px;
    padding: 10px 25px 0 25px;
    font-size: ${paragraphSmallSize};
  }

  .badges {
    margin-top: ${SPACING_16};
    display: flex;
    flex: row nowrap;
    justify-content: center;
  }
`;

const StyledDisclaimer = styled.a`
  color: ${textMain};
  text-decoration: none;

  span {
    word-wrap: break-word;
    text-decoration: underline;
  }
`;

const StyledFooter = styled.footer`
  position: absolute;
  left: 0;
  bottom: -70px;
  padding: 0 10%;

  @media ${mobileCondition} {
    font-size: 12px;
    bottom: -95px;
  }
`;

const StyledSupportLink = styled(StyledDisclaimer)`
  color: ${textLightest};
  font-size: ${SPACING_16};
  font-weight: 500;

  span,
  a {
    font-weight: 500;
    color: inherit;
  }
`;

const CenteredParagraph = styled.p`
  text-align: center;
`;

const StyledBankIdLogin = styled(BankIdLogin)`
  margin-bottom: ${SPACING_16};
`;

const StyledPasskeyLogin = styled(PasskeyLogin)`
  margin-bottom: ${SPACING_16};
`;

const StyledLogo = styled(Logo)`
  position: absolute;
  top: 34px;
  left: 40px;

  @media ${mobileCondition} {
    top: ${SPACING_16};
    left: ${SPACING_16};
  }
`;

const StyledError = styled(Paragraph)`
  margin-bottom: ${SPACING_16};
  background: ${backgroundWhite};
  padding: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const StyledLanguageSelectorDropDown = styled(LanguageSelectorDropDown)`
  position: absolute;
  top: 34px;
  right: 40px;

  &:hover {
    background-color: #38579566;
  }

  @media ${mobileCondition} {
    top: ${SPACING_12};
    right: 0;
  }
`;

const StyledButtonSmall = styled(ButtonSmall)`
  position: absolute;
  top: 34px;
  right: 120px;
  border-color: ${backgroundWhite};
  color: ${textLightest};

  &:hover:not(:disabled) {
    background-color: unset;
  }

  @media ${mobileCondition} {
    top: 12px;
    right: 80px;
  }
`;

const ConsentDisclaimer = (props) => {
  const consentText = t('login:consentDisclaimer');
  const consentLinkText = t('login:consentDisclaimerLinkText');
  const consentLink = t('login:consentDisclaimerLink');

  return (
    <ParagraphSmall>
      <StyledDisclaimer target="_blank" href={consentLink}>
        {consentText} <span>{consentLinkText}</span>
      </StyledDisclaimer>
    </ParagraphSmall>
  );
};

const SupportLink = (props) => {
  // TODO:country - Use international support mail?
  const text = t('login:supportText', {
    context: config.countryCode,
  });
  const link = t('login:supportMail', {
    context: config.countryCode,
  });

  return (
    <CenteredParagraph>
      <StyledSupportLink target="_blank" href={`mailto:${link}`}>
        {text} <span>{link}</span>
      </StyledSupportLink>
    </CenteredParagraph>
  );
};

export const LoginPage = (props) => {
  const { error } = props?.location?.state ?? {};
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { authMethods } = config.app;
  const [, setCookie] = useCookies(['codeVerifier']);

  const [codeVerifier] = useState(generateCodeVerifier());
  const [codeVerifierSaved, setCodeVerifierSaved] = useState(false);

  // Only show passkey login option if we know the user as registered a passkey
  // We also need to make sure the browser supports WebAuthn
  const passkeysEnabled = useSelector(
    (state) => state.webauthn.passkeysEnabled
  );
  const showPasskeyLoginOption = passkeysEnabled && browserSupportsWebAuthn();

  useEffect(() => {
    if (!codeVerifierSaved) {
      // Store the code verifier in a cookie so we can access it when we
      // get back from the redirect loop
      setCookie('codeVerifier', codeVerifier, { path: '/', maxAge: 60 * 5 });
      // Some browser seems to have problem with setting the cookie, so in an attempt
      // to try work around that we use session storage as well. We also had issues with
      // some browsers not allowing session storage, so this is belt _and_ suspenders
      // in an attempt to always be able to retreive the code verifier on the auth
      // callback page.
      window.sessionStorage.setItem('codeVerifier', codeVerifier);
      setCodeVerifierSaved(true);
    }
  }, [codeVerifierSaved, setCookie, codeVerifier]);

  // Enable username login by passing in a username query param
  const [username] = useQueryState('username', '');

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  let errorMessage = null;
  if (error) {
    switch (error) {
      case 'AccessDenied':
        errorMessage = t('login:errors:accessDenied');
        break;
      case 'SessionTimeout':
        errorMessage = t('login:errors:sessionTimeout');
        break;
      case 'CookieProblem':
        errorMessage = t('login:errors:cookieProblem');
        break;
      default:
        errorMessage = t('login:errors:unknown');
        break;
    }
  }

  return (
    <>
      <OperationalMessages />
      <FullscreenPage>
        <div style={{ position: 'relative' }}>
          <StyledLogo height="26" monochrome={true} />
          <StyledLanguageSelectorDropDown />

          <StyledButtonSmall buttonstyle="outline" to="/registration">
            {t('login:registration:demoButton')}
            <SpriteIconFunctional
              id="Pointer arrow"
              direction="Right"
              color={backgroundWhite}
            />
          </StyledButtonSmall>

          <StyledContentWrapper position="center">
            <LoginBox>
              <StyledHeader>
                <H2 as="h1">
                  <SpriteIcon id="Lock" size={30} />
                  {t('login:title')}
                </H2>
                {errorMessage && <StyledError>{errorMessage}</StyledError>}
                <ConsentDisclaimer />
              </StyledHeader>

              {showPasskeyLoginOption ? (
                <StyledPasskeyLogin />
              ) : (
                authMethods.map((method) => (
                  <div key={method}>
                    <StyledBankIdLogin
                      method={method}
                      codeVerifier={codeVerifier}
                    />
                  </div>
                ))
              )}

              {(config.dev || username) && (
                <UsernameLogin username={username} />
              )}

              <DemoInfo>
                <ParagraphSmall style={{ fontWeight: 400 }}>
                  {t('login:registration:title')}
                </ParagraphSmall>
                <ParagraphSmall>
                  {t('login:registration:description')}
                </ParagraphSmall>
              </DemoInfo>

              <MobileAppInfo>
                <Paragraph style={{ fontWeight: 400, margin: '0 auto' }}>
                  {t('login:appLinksTitle')}
                </Paragraph>

                <div className="badges">
                  <AppStoreBadge height="40" />
                  <PlayStoreBadge height="40" />
                </div>
              </MobileAppInfo>

              <StyledFooter>
                <SupportLink />
              </StyledFooter>
            </LoginBox>
          </StyledContentWrapper>
        </div>
      </FullscreenPage>
    </>
  );
};
