import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  useResource,
  selectors,
  toLocaleDateString,
  formatMoney,
  filterList,
  t,
  localizeFloat,
} from '@formue-app/core';

import { SPACING_16, SPACING_32, SPACING_8 } from '../../../constants/spacing';

import { BaseSection } from '../../common/BaseSection';
import { H4 } from '../../texts';
import {
  StyledCell,
  StyledHeaderCell,
  StyledRow,
  TableList,
} from '../../lists';
import { useQueryState } from 'react-router-use-location-state';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { printCondition } from '../../../constants/media';
import { accent } from '../../../constants/colors';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const StyledTableList = styled(TableList)`
  border-spacing: 0 !important;
`;

const LightStyledRow = styled(StyledRow)`
  &:nth-child(odd) {
    background: ${accent.sand150};
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
`;

const LightStyledHeaderCell = styled(StyledHeaderCell)`
  @media ${printCondition} {
    font-size: 10px;
    text-align: left;
    vertical-align: bottom;
  }

  &:first-child {
    padding-left: ${SPACING_16};
    border-radius: 0;
  }

  &:last-child {
    padding-right: ${SPACING_16};
    border-radius: 0;
  }
`;

const LightStyledCell = styled(StyledCell)`
  padding: ${SPACING_8};

  &:first-child {
    padding-left: ${SPACING_16};
    border-radius: 0;
  }

  &:last-child {
    padding-right: ${SPACING_16};
    border-radius: 0;
  }
  span {
    font-weight: 400;
    text-align: left;
  }

  @media ${printCondition} {
    span {
      font-size: 10px;
      vertical-align: bottom;
    }
  }
`;

const StyledBaseSection = styled(BaseSection)`
  @media ${printCondition} {
    padding: 0 !important;
    border: none;
    background: none;

    H4 {
      padding-bottom: ${SPACING_8};
      border-bottom: 2px solid ${accent.sand150};
    }
  }
`;

const {
  entities: {
    transactions: { transactionAllIdsSelector, transactionByIdSelector },
  },
} = selectors;

export const PeriodicTransactions = (props) => {
  const loading = useResource(['TRANSACTIONS/INDEX']);

  const [transactionType, setTransactionType] = useQueryState(
    'transactionType',
    ''
  );
  const [product, setProduct] = useQueryState('product', '');
  const filters = {
    type: {
      type: 'select',
      value: transactionType,
      onChange: setTransactionType,
      label: t('portfolio:transaction'),
    },
    product: {
      type: 'select',
      value: product,
      onChange: setProduct,
      label: t('portfolio:product'),
    },
  };

  const allIds = useSelector(transactionAllIdsSelector);
  const byId = useSelector(transactionByIdSelector);
  const [transactions] = filterList(allIds, byId, filters);

  // Define the table columns
  let columns = [
    {
      key: 'date',
      label: t('globals:date'),
      render: ({ date }) => toLocaleDateString(date),
      defaultSort: true,
      width: '10%',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'product',
      label: t('portfolio:product'),
      width: '30%',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'type',
      label: t('portfolio:transaction'),
      width: '13%',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'price',
      label: t('portfolio:price'),
      render: ({ price: p }) => (p ? localizeFloat(p, 2) : ''),
      width: '13%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'amount',
      label: t('globals:amount'),
      render: ({ amount: a }) => (a ? formatMoney(a) : ''),
      width: '13%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
  ];

  return (
    <SectionWrapper>
      {loading ? (
        <CenteredActivityIndicator />
      ) : (
        <>
          <StyledBaseSection columns={12}>
            <StyledTableList
              id="holdingsList"
              sortable={false}
              columns={columns}
              rows={transactions}
              rowComponent={LightStyledRow}
              keyExtractor={(item, index) => `asset-class-${index}`}
              stickyHeader={true}
            />
          </StyledBaseSection>
        </>
      )}
    </SectionWrapper>
  );
};
