import React from 'react';
import styled from 'styled-components';

import { useResource, selectors, t, percentage } from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { SPACING_24, SPACING_8 } from '../../../constants/spacing';
import { H2, ParagraphSmall } from '../../texts';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { useSelector } from 'react-redux';
import { printCondition } from '../../../constants/media';
import { PortfolioReportingChart } from '../charts/PortfolioReportingChart';
import { accent } from '../../../constants/colors';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};

  @media ${printCondition} {
    margin-left: ${SPACING_24} !important;
    margin-right: ${SPACING_24} !important;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const {
  entities: {
    portfolios: { portfolioTotalSelector },
  },
} = selectors;

export const PeriodicGain = (props) => {
  const loading = useResource(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);

  const portfolioTotal = useSelector(portfolioTotalSelector);

  const colorSet = [
    '#99BBDE',
    accent.ocean170,
    accent.ocean490,
    accent.sand1,
    accent.sand1,
  ];

  return (
    <StyledBaseSection columns={6}>
      <TextWrapper>
        <ParagraphSmall>
          {t('portfolio:periodicReporting:benchmark')}
        </ParagraphSmall>
        <H2>{percentage(portfolioTotal?.twr * 100, 1)}</H2>
      </TextWrapper>

      {loading ? (
        <CenteredActivityIndicator />
      ) : (
        <PortfolioReportingChart
          dataExtractor="twr"
          colorSet={colorSet}
          height={100}
        />
      )}
    </StyledBaseSection>
  );
};
