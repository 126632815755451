import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { groupBy } from 'lodash';

import {
  t,
  usePortfolioRelationshipFilter,
  selectors,
  useStartegyOptions,
} from '@formue-app/core';

import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
  SPACING_24,
  SPACING_4,
  SPACING_8,
} from '../../../constants/spacing';
import {
  accent,
  backgroundWhite,
  hoverBoxShadowLvl1,
  hoverBoxShadowLvl2,
} from '../../../constants/colors';
import { mobileCondition } from '../../../constants/media';

import {
  FilledSelectStyle,
  OutlineSelectStyle,
  StyledInputBase,
} from '../../formElements';
import { Paragraph } from '../../texts';
import { easeInOut, shortAnimationTime } from '../../../constants/animation';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';
import { ToggleFilterGroupDetails, ToggleFilterGroupTitle } from '../filters';
import { StrategyPickerContent } from '../strategy/StrategyPicker';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${SPACING_4};
`;

const Select = styled.div`
  ${StyledInputBase}
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_8};
  cursor: pointer;
  max-width: 416px;

  ${(props) => props.inputstyle === 'filled' && FilledSelectStyle}
  ${(props) => props.inputstyle === 'outline' && OutlineSelectStyle}


   & > svg {
    transition: rotate ${easeInOut} ${shortAnimationTime};
    ${(props) =>
      props.position === 'top'
        ? css`
            rotate: ${(props) => (props.open ? '0deg' : '180deg')};
          `
        : css`
            rotate: ${(props) => (props.open ? '180deg' : '0deg')};
          `}
  }
`;

const StyledSpriteIconFunctional = styled(SpriteIconFunctional)`
  margin-right: 3px;
  margin-left: ${SPACING_16};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  background: ${backgroundWhite};
  padding: ${SPACING_24};
  position: absolute;
  width: 416px;
  right: 0;
  box-shadow: ${hoverBoxShadowLvl2};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  z-index: 3;

  ${(props) =>
    props.position === 'bottom'
      ? css`
          translate: 0 100%;
          bottom: -5px;
        `
      : css`
          top: -5px;
          translate: 0 -100%;
        `}

  @media ${mobileCondition} {
    width: 100%;
    box-shadow: ${hoverBoxShadowLvl1};
  }
`;

const StyledFilterGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${accent.sand150};
  padding: ${SPACING_24} ${SPACING_16};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  gap: ${SPACING_24};
`;

const StyledStrategyPickerContent = styled(StrategyPickerContent)`
  position: relative;
  bottom: 0;
  width: auto;
  translate: unset;
  box-shadow: none;
  background: ${accent.sand150};
`;

const {
  entities: {
    accounts: { allAccountsSelector },
  },
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

const FilterGroupWrapper = (props) => {
  const { options, title } = props;

  if (!options.length) return null;

  return (
    <StyledFilterGroupWrapper>
      <ToggleFilterGroupTitle title={title} options={options} />
      <ToggleFilterGroupDetails title={title} options={options} />
    </StyledFilterGroupWrapper>
  );
};

export const ReportingStrategyFilter = (props) => {
  const { arrowColor = accent.ocean490, position = 'bottom', ...rest } = props;

  const [open, setOpen] = useState(false);
  let [activeStrategy, setActiveStrategy] = useState(null);
  const wrapperRef = useRef(null);

  const relationshipFilter = usePortfolioRelationshipFilter();
  const accounts = useSelector(allAccountsSelector);
  const activeStrategyId = useSelector(activeStrategySelector);
  const allStrategies = useStartegyOptions(true);
  const combinedStrategies = groupBy(allStrategies, 'accounts');

  useEffect(() => {
    const strategy = allStrategies.find(
      (strategy) => strategy.id === activeStrategyId
    );

    // If we dont have a strategy awailable due to filtering of accounts
    // we set the first strategy as active
    // Whenever the active account is turned on again and active it will be the active strategy
    if (!strategy) {
      setActiveStrategy(allStrategies[0]);
    } else {
      setActiveStrategy(strategy);
    }
  }, [activeStrategyId, allStrategies]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Wrapper ref={wrapperRef} {...rest}>
      <Select onClick={() => setOpen(!open)} open={open} inputstyle="outline">
        <Paragraph style={{ marginRight: 'auto' }}>
          {t('filters:relationshipsTitle')}
        </Paragraph>{' '}
        <StyledSpriteIconFunctional
          id="Simple arrow"
          direction="Down"
          color={arrowColor}
          size={14}
        />
      </Select>
      {open ? (
        <ContentWrapper position={position}>
          <FilterGroupWrapper
            title={t('filters:relationshipsTitle')}
            options={relationshipFilter}
          />

          <StyledStrategyPickerContent
            accounts={accounts}
            position={position}
            color={accent.ocean420}
            combinedStrategies={combinedStrategies}
            activeStrategy={activeStrategy}
          />
        </ContentWrapper>
      ) : null}
    </Wrapper>
  );
};
