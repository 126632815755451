export const whiteTranslucent = (percent) => `rgba(255, 255, 255, ${percent})`;
export const blackTranslucent = (percent) => `rgba(0, 0, 0, ${percent})`;

export const hoverBoxShadowLvl1 = `0 4px 12px ${blackTranslucent(0.05)}`;
export const hoverBoxShadowLvl2 = `0 2px 12px ${blackTranslucent(0.05)}`;
export const hoverBoxShadowLvl3 = `0 2px 5px ${blackTranslucent(0.05)}`;
export const buttonBoxShadow = `0 2px 3px 1px ${blackTranslucent(0.12)}`;

export const elevatedBoxShadow = `0 4px 4px ${blackTranslucent(0.25)}`;
export const elevatedBoxShadowSmooth = `0px 2px 6px ${blackTranslucent(0.2)}`;

export const backgroundWhite = '#FFFFFF';
export const backgroundWhiteTranslucent = whiteTranslucent(0.5);
export const backgroundMain = '#F5F3F0';
export const primary = '#182461';

export const textMain = '#2F3A71';
export const textLightest = backgroundWhite;
export const textLink = '#0056B2';

export const uiAffirmative = '#39A758';
export const uiActive = '#2A8AEA';
export const uiNegative = '#EB6077';
export const uiHover = '#F0F5F9';

export const buttoncolor = '#385795'; // O1
export const buttoncolorhover = '#2B4271';
export const buttoncoloractive = '#2B4271';
export const buttoncolordisabled = '#E9E9ED';

export const overlayColor = blackTranslucent(0.5);

export const prinTextMain = '#182361';
export const prinTextMainSubtle = '#A3A7C0';

export const accent = {
  white: backgroundWhite,
  darkBlue: primary,
  lightBlue: '#46A0FF',
  beige: '#E5CFA1',
  lightBeige: '#E4DCD2',
  burgundy: '#63082E',
  darkGreen: '#0F4740',
  purple: '#472088',
  peach: '#D46543',
  wineRed: '#B82812',
  lightYellow: '#FFEE93',
  salmon: '#FF9691',
  magenta: '#992E63',
  ocean1: '#385795',
  ocean170: '#7489B5',
  ocean120: '#D7DDEA',
  ocean190: '#4C68A0',
  ocean2: '#99BBDE',
  ocean210: '#F5F8FC',
  ocean220: '#EBF1F8',
  ocean230: '#E0EBF5',
  ocean250: '#CCDDEF',
  ocean270: '#B8CFE8',
  ocean3: '#45A2FF',
  ocean330: '#C7E3FF',
  ocean370: '#7DBEFF',
  ocean4: '#182361',
  ocean408: '#EDEDF2',
  ocean410: '#E1E0E4',
  ocean415: '#DCDEE8',
  ocean420: '#D1D3DF',
  ocean440: '#A3A7C0',
  ocean450: '#8B91B0',
  ocean470: '#5D6590',
  ocean480: '#E8EFF7',
  ocean490: '#2F3A71',
  raspberry1: '#96355D',
  raspberry190: '#A0496D',
  raspberry2: '#E3AAB3',
  raspberry270: '#EBC3CA',
  raspberry250: '#F1D5D9',
  raspberry3: '#F2637B',
  raspberry330: '#FBD0D7',
  raspberry370: '#F692A3',
  raspberry4: '#4F143B',
  raspberry480: '#724362',
  raspberry490: '#612C4F',
  forest1: '#437047',
  forest170: '#7B9B7E',
  forest190: '#567E59',
  forest130: '#C7D4C8',
  forest2: '#ABD4A3',
  forest230: '#E6F3E3',
  forest250: '#D5E9D1',
  forest3: '#6ECA5B',
  forest330: '#D3EFCE',
  forest370: '#99DA8C',
  forest4: '#173621',
  forest490: '#2E4A37',
  velvet1: '#6D4694',
  velvet120: '#E2DAEA',
  velvet170: '#997DB4',
  velvet190: '#7C599F',
  velvet2: '#C5A5D6',
  velvet210: '#EAE8EE',
  velvet270: '#D6C0E2',
  velvet250: '#E2D2EB',
  velvet3: '#C76FF2',
  velvet330: '#EED4FB',
  velvet4: '#301B59',
  velvet480: '#59497A',
  velvet490: '#45326A',
  velvet5: '#810CBB',
  moss1: '#437047',
  moss2: '#ABD4A3',
  moss3: '#6ECA5B',
  moss4: '#173621',
  musterd1: '#905B25',
  musterd2: '#E5CC7E',
  musterd270: '#EDDBA5',
  musterd250: '#F2E5BF',
  musterd3: '#F0BA18',
  musterd330: '#FBEABA',
  musterd4: '#45241D',
  sand1: '#F5F3F0',
  sand110: '#E5E1DA',
  sand140: '#FBFAF9',
  sand150: '#FAF9F7',
  sand2: '#EAE2D7',
  sand250: '#F2EDE7',
  sand260: '#eee9e1',
  sand3: '#ECE6DD',
  sand370: '#E9E2D7',
  sand4: '#DECDB2',
  neutral150: '#FAF9F7',
};

export const checkboxCustom = {
  border: '#999081',
  background: uiActive,
};

export const assetClassDefaultColorScheme = [
  accent.velvet1,
  accent.velvet2,
  accent.velvet3,
];
