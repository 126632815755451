import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';

import { getFlagsSelector, flattenRoutes } from '@formue-app/core';

import { ContentWrapper, GridElement, MainGrid } from '../../components/layout';
import { PopoverBase } from '../../components/popover/PopoverBase';
import { BasePage } from './BasePage';

import { PageTitle } from '../../components/common/PageTitle';
import { ProfileBar } from '../../components/common/ProfileBar';
import { BackButton } from '../../components/common/BackButton';

import { MainMenu } from '../../components/navigation/MainMenu';

import {
  mobileCondition,
  MobileWrapper,
  NotMobileWrapper,
  PrintWrapper,
} from '../../constants/media';
import {
  menuClosedContentMaxWitdh,
  layoutMaxWitdh,
  menuMaxWidth,
  menuClosedWidth,
} from '../../constants/grid';
import { OperationalMessages } from '../../components/operationalMessages/OperationalMessages';
import { Logo } from '../../components/common/Logo';
import { SPACING_16 } from '../../constants/spacing';
import { AdvisorProfileBar } from '../../components/advisorsCorner/AdvisorProfileBar';
import { ErrorFallback } from '../../components/common/ErrorFallback';
import { DemoIndicator } from '../../components/common/DemoIndicator';

const StyledMainGrid = styled(MainGrid)`
  margin-bottom: 70px;

  ${(props) =>
    !props.menuOpen &&
    `
    max-width: ${menuClosedContentMaxWitdh}px;`}

  @media ${mobileCondition} {
    margin-top: 70px; // Height of mobile menu bar
  }
`;

const CenterContent = styled.div`
  max-width: ${layoutMaxWitdh}px;
  flex: 1;

  ${(props) =>
    !props.menuOpen &&
    `
    max-width: ${layoutMaxWitdh + menuMaxWidth - menuClosedWidth}px;`}
`;

export const MenuPage = (props) => {
  const {
    children,
    title,
    subTitle,
    gridTemplate,
    subpage = false,
    icon,
    size,
    routes = [],
    location,
    titleStyles,
    showIcon,
    backOverrideUrl,
    hideHeaderForPrint = false,
  } = props;
  const { mainMenuOpen } = useSelector((state) => state.ui.navigation);
  const { isLead } = useSelector(getFlagsSelector);
  const isEmployee = useSelector((state) => state.auth.isEmployee);

  const subPageProps = flattenRoutes(routes).find(
    (item) => item.path === location.pathname
  );

  return (
    <BasePage {...props}>
      <MobileWrapper>
        <OperationalMessages />
      </MobileWrapper>
      <ContentWrapper>
        <MainMenu mainMenuOpen={mainMenuOpen} subPageProps={subPageProps} />

        <CenterContent menuOpen={mainMenuOpen}>
          <NotMobileWrapper>
            <OperationalMessages />
          </NotMobileWrapper>
          {isEmployee ? <AdvisorProfileBar /> : null}
          <StyledMainGrid menuOpen={mainMenuOpen} gridTemplate={gridTemplate}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {!isEmployee ? <ProfileBar columns={12} /> : null}

              {!hideHeaderForPrint ? null : (
                <PrintWrapper>
                  <GridElement columns={12}>
                    <Logo
                      style={{
                        verticalAlign: 'bottom',
                        marginBottom: SPACING_16,
                      }}
                      monochrome={false}
                      height="24"
                    />
                  </GridElement>
                </PrintWrapper>
              )}

              {subpage && (
                <BackButton columns={12} backOverrideUrl={backOverrideUrl} />
              )}
              <PageTitle
                columns={9}
                subTitle={subTitle}
                title={title}
                icon={icon}
                size={size}
                subPageProps={subPageProps}
                style={
                  subpage ? { ...titleStyles, paddingTop: 0 } : titleStyles
                }
                showIcon={showIcon}
                hideHeaderForPrint={hideHeaderForPrint}
              />
              {children}
            </ErrorBoundary>
          </StyledMainGrid>
        </CenterContent>
        <PopoverBase />
      </ContentWrapper>
      {isLead ? <DemoIndicator /> : null}
    </BasePage>
  );
};
