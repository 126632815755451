import { handleActions } from 'redux-actions';
import { actions } from './actions';
export { actions };

export { sagas } from './sagas';
import * as selectors from './selectors';
export { selectors };

const defaultState = {
  filters: {
    period: 'FIVEYEARS',
    startDate: null,
    endDate: null,
    excludedSsids: [],
    externalReporting: ['showInternalPortfolio', 'showExternalPortfolio'],
    excludedTaxClasses: [],
    excludedInvestmentAccounts: [],
  },
  filterValues: {
    period: [
      'THISMONTH',
      'THISYEAR',
      'TWELVEMONTHS',
      'THREEYEARS',
      'FIVEYEARS',
      'TENYEARS',
    ],
    externalReporting: ['showInternalPortfolio', 'showExternalPortfolio'],
  },
  activeStrategyId: null,
  defaultFilters: null,
  updatedAt: null,
  calculatedAt: null,
  initialized: false,
};

export const reducer = handleActions(
  {
    [actions.filterPeriod]: (state, { payload }) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          period: payload.period,
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
      };
    },

    [actions.filterSsids]: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        excludedSsids: payload,
      },
    }),

    [actions.filterExternalReporting]: (state, { payload }) => {
      const { externalReporting } = state.filters;
      let result;
      if (externalReporting.includes(payload)) {
        result = externalReporting.filter((item) => item !== payload);
      } else {
        result = [...externalReporting, payload];
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          externalReporting: result,
        },
      };
    },

    [actions.filterTaxClass]: (state, { payload }) => {
      const { excludedTaxClasses } = state.filters;
      let result;
      if (excludedTaxClasses.includes(payload)) {
        result = excludedTaxClasses.filter((item) => item !== payload);
      } else {
        result = [...excludedTaxClasses, payload];
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          excludedTaxClasses: result,
        },
      };
    },

    [actions.filterInvestmentAccount]: (state, { payload }) => {
      const { excludedInvestmentAccounts } = state.filters;

      let result;

      // Check if payload contains a single account, or an array of accounts
      const payloadIsArray = Array.isArray(payload);
      if (payloadIsArray) {
        // if we get an array, we need to check excluded accounts against every item
        if (
          excludedInvestmentAccounts.some((excludedAccount) =>
            payload.includes(excludedAccount)
          )
        ) {
          // filter out every account in payload to re-enable them
          result = excludedInvestmentAccounts.filter(
            (item) => !payload.includes(item)
          );
        } else {
          // Add every account in payload to excluded list
          result = [...excludedInvestmentAccounts, ...payload];
        }
      } else {
        if (excludedInvestmentAccounts.includes(payload)) {
          result = excludedInvestmentAccounts.filter(
            (item) => item !== payload
          );
        } else {
          result = [...excludedInvestmentAccounts, payload];
        }
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          excludedInvestmentAccounts: result,
        },
      };
    },

    [actions.defaultFiltersLoaded]: (state, { payload }) => ({
      ...state,
      filters: payload ? payload : state.filters,
      defaultFilters: payload,
    }),

    [actions.defaultFiltersSave]: (state) => ({
      ...state,
      defaultFilters: state.filters,
    }),

    [actions.defaultFiltersDelete]: (state) => ({
      ...state,
      defaultFilters: null,
    }),

    [actions.activeStrategy]: (state, { payload }) => ({
      ...state,
      activeStrategyId: payload,
    }),

    [actions.storePortfolioTimestamps]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },

    [actions.flush]: (state) => defaultState,
    [actions.initialized]: (state) => ({
      ...state,
      initialized: true,
    }),
  },
  defaultState
);
