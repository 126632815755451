import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions,
  formatDateRangeLabel,
  getPredefinedRanges,
} from '@formue-app/core';

import { Paragraph } from '../../texts';
import {
  FilledSelectStyle,
  OutlineSelectStyle,
  StyledInputBase,
} from '../../formElements';
import { PeriodFilterModal } from '../filters/PeriodFilterModal';

const Select = styled.div`
  ${StyledInputBase}
  cursor: pointer;
  z-index: 1;
  max-width: 416px;

  ${(props) => props.inputstyle === 'filled' && FilledSelectStyle}
  ${(props) => props.inputstyle === 'outline' && OutlineSelectStyle}
`;

const {
  ui: { portfolio: uiPortfolioActions },
} = actions;

export const ReportingPeriodFilter = (props) => {
  const { inputstyle = 'outline', ...rest } = props;
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const { period, startDate, endDate } = useSelector(
    (state) => state.ui.portfolio.filters
  );

  const onChange = (data) => {
    const [period, startDate, endDate] = data;

    dispatch(
      uiPortfolioActions.filterPeriod({
        period: period,
        startDate: startDate?.toISOString().split('T')[0],
        endDate: endDate?.toISOString().split('T')[0],
      })
    );
  };

  const { newStartDate, newEndDate } = getPredefinedRanges(period);

  return (
    <div {...rest}>
      <Select onClick={() => setModalOpen(true)} inputstyle={inputstyle}>
        <Paragraph style={{ fontWeight: 500 }}>
          {formatDateRangeLabel(
            startDate || newStartDate,
            endDate || newEndDate
          )}
        </Paragraph>
      </Select>

      <PeriodFilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onChange={onChange}
      />
    </div>
  );
};
