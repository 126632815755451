import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  useResource,
  selectors,
  toLocaleDateString,
  formatMoney,
  getAssetClassName,
  percentage,
  t,
} from '@formue-app/core';

import { SPACING_16, SPACING_8 } from '../../../constants/spacing';

import { BaseSection } from '../../common/BaseSection';
import {
  StyledCell,
  StyledHeaderCell,
  StyledRow,
  TableList,
} from '../../lists';

import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { printCondition } from '../../../constants/media';
import { accent } from '../../../constants/colors';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const StyledTableList = styled(TableList)`
  border-spacing: 0 !important;
`;

const LightStyledHeaderCell = styled(StyledHeaderCell)`
  @media ${printCondition} {
    font-size: 10px;
    text-align: right;
    vertical-align: bottom;
  }

  &:first-child {
    padding-left: ${SPACING_16};
    border-radius: 0;
  }

  &:last-child {
    padding-right: ${SPACING_16};
    border-radius: 0;
  }
`;

const LightStyledRow = styled(StyledRow)`
  &:first-child {
    th {
      padding-top: 0;
    }
  }

  &:nth-last-child(2) {
    border-bottom: 2px solid ${accent.sand150};
  }

  &:last-child {
    th {
      padding-top: ${SPACING_16};
      padding-bottom: ${SPACING_16};
    }

    span {
      font-weight: 600;
    }
  }

  &:nth-child(odd) {
    background: ${accent.sand150};
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  &:has(+ .subHeading) th span {
    font-weight: 600;
  }
`;

const LightStyledCell = styled(StyledCell)`
  padding: ${SPACING_8};

  &:first-child {
    padding-left: ${SPACING_16};
    border-radius: 0;
  }

  &:last-child {
    padding-right: ${SPACING_16};
    border-radius: 0;
  }

  span {
    font-weight: 400;
    text-align: left;
  }

  &:nth-last-child(-n + 2) {
    span {
      font-weight: 600;
    }
  }

  @media ${printCondition} {
    span {
      font-size: 10px;
      vertical-align: bottom;
    }
  }
`;

const StyledBaseSection = styled(BaseSection)`
  @media ${printCondition} {
    padding: 0 !important;
    border: none;
    background: none;

    H4 {
      padding-bottom: ${SPACING_8};
      border-bottom: 2px solid ${accent.sand150};
    }
  }
`;

const {
  entities: {
    portfolios: {
      portfolioTotalSelector,
      portfolioAssetClassesWithSubitemsSelector,
    },
  },
} = selectors;

export const PeriodicHoldings = (props) => {
  const loading = useResource(['PORTFOLIOS/INDEX']);

  const assetClasses = useSelector(portfolioAssetClassesWithSubitemsSelector);
  const portfolioTotal = useSelector(portfolioTotalSelector);

  // Define the table columns
  let holdingsColumns = [
    {
      key: 'p',
      label: '',
      defaultSort: true,
      width: '20%',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'quantity',
      label: t('portfolio:holdingsTable:amountShort'),
      render: ({ quantity }) => formatMoney(quantity),
      width: '11%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'costPrice',
      label: t('portfolio:holdingsTable:costPrice'),
      render: ({ costPrice }) => formatMoney(costPrice),
      width: '11%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'priceDate',
      label: t('portfolio:holdingsTable:dateShort'),
      render: ({ priceDate }) => toLocaleDateString(priceDate),
      width: '11%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'mv',
      label: t('portfolio:holdingsTable:marketValue'),
      render: ({ mv }) => formatMoney(mv),
      width: '11%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'unrealizedGain',
      label: t('portfolio:holdingsTable:unrealizedReturnSinceStart'),
      render: ({ unrealizedGain }) => formatMoney(unrealizedGain),
      width: '11%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'gain',
      label: t('portfolio:holdingsTable:returnKroner'),
      render: ({ gain }) => formatMoney(gain),
      width: '11%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'twr',
      label: t('portfolio:holdingsTable:returnPercentage'),
      render: ({ twr }) => percentage(twr * 100),
      width: '11%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
  ];

  const tableData = assetClasses
    .map((item, index) => {
      const assetClassTitle = getAssetClassName(item.class);
      const items = [
        { subHeading: true, title: assetClassTitle },
        ...item.subItems.map((subItem) => ({
          ...subItem,
          priceDate: !subItem.mv ? null : subItem.priceDate,
        })),
      ];

      items.push({
        p: `Totalt ${assetClassTitle}`,
        quantity: item.quantity,
        priceDate: item.priceDate,
        costPrice: item.costPrice,
        unrealizedGain: item.unrealizedGain,
        gain: item.gain,
        twr: item.twr,
        mv: item.mv,
      });

      return items;
    })
    .flat();

  // Add total to the dataset
  tableData.push({
    ...portfolioTotal,
    p: t('portfolio:periodicReporting:holdings:totalPortfolio'),
  });

  return (
    <SectionWrapper>
      {loading ? (
        <CenteredActivityIndicator />
      ) : (
        <>
          <StyledBaseSection columns={12}>
            <StyledTableList
              id="holdingsList"
              sortable={false}
              columns={holdingsColumns}
              rows={tableData}
              rowComponent={LightStyledRow}
              keyExtractor={(item, index) => `asset-class-${index}`}
              stickyHeader={true}
            />
          </StyledBaseSection>
        </>
      )}
    </SectionWrapper>
  );
};
