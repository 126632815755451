export const groupBy = (array, key) => {
  return array.reduce((result, item) => {
    const groupKey = key instanceof Function ? key(item) : item[key];
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(item);
    return result;
  }, {});
};
