import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
  SPACING_24,
  SPACING_32,
  SPACING_48,
  SPACING_8,
} from '../../constants/spacing';
import {
  desktopCondition,
  mobileCondition,
  notDesktopCondition,
  printCondition,
  tabletCondition,
} from '../../constants/media';

import { H2, ParagraphSmall } from '../texts';
import { SubPageNavigationItem } from './SubPageNavigationItem';
import { SpriteIcon } from './SpriteIcon';
import { BaseSection } from './BaseSection';

const StyledContainer = styled(BaseSection)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${SPACING_32};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  @media ${notDesktopCondition} {
    flex-direction: column;
  }

  @media ${mobileCondition} {
    background: transparent;
    padding: 0;
  }

  @media ${printCondition} {
    display: none;
  }
`;

const StyledTextTitle = styled(H2)`
  margin-bottom: 12px;
  @media ${tabletCondition} {
    font-size: 22px;
  }
  @media ${mobileCondition} {
    font-size: 24px;
    margin-bottom: ${SPACING_16};
  }
`;

const StyledTextWrapper = styled.div`
  @media ${desktopCondition} {
    max-width: 330px;
    margin-right: ${SPACING_24};
  }
  @media ${tabletCondition} {
    text-align: center;
    margin-bottom: 24px;
  }

  @media ${mobileCondition} {
    margin-bottom: ${SPACING_48};
  }
`;

const StyledNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${SPACING_8};

  @media ${notDesktopCondition} {
    flex-wrap: wrap;
  }

  @media ${mobileCondition} {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const SubPageNavigation = (props) => {
  const { routes = [], title, subTitle } = props;

  const isMobile = useMediaQuery({
    query: mobileCondition,
  });
  return (
    <StyledContainer columns={12}>
      <StyledTextWrapper>
        <StyledTextTitle>{title}</StyledTextTitle>
        <ParagraphSmall>{subTitle}</ParagraphSmall>
      </StyledTextWrapper>
      <StyledNavigationWrapper>
        {routes.map((route, index) => {
          const disabled = !route.hasOwnProperty('component');

          return (
            <SubPageNavigationItem
              {...route}
              key={`item-${index}`}
              icon={
                <SpriteIcon
                  id={route.icon}
                  inverted={window.location.pathname === route.path}
                  size={isMobile ? 24 : 16}
                />
              }
              disabled={disabled}
            />
          );
        })}
      </StyledNavigationWrapper>
    </StyledContainer>
  );
};
