import React, { useState } from 'react';
import styled from 'styled-components';

import {
  selectors,
  formatHistoricalPerformanceChartData,
  t,
  percentage,
  getYearlyReturn,
} from '@formue-app/core';

import { H4, ParagraphSmall } from '../texts';
import { BaseSection } from '../common/BaseSection';
import { SPACING_12, SPACING_16, SPACING_8 } from '../../constants/spacing';
import { useSelector } from 'react-redux';
import { InvestmentStrategyAreaChart } from './charts/InvestmentStrategyAreaChart';
import { accent } from '../../constants/colors';
import { TimeHorizonSelect } from '../presentation/investmentStrategyComponents/TimeHorizonSelect';

const Container = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Wrapper = styled.div`
  flex: 1;
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_8};
`;

const TitleWrapper = styled(ValueWrapper)`
  gap: ${SPACING_12};
  row-gap: 0;
  padding-bottom: ${SPACING_16};
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Separator = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${accent.sand150};
`;

const Row = styled.div`
  display: flex;
  flex: 1;
`;

const {
  entities: {
    investmentStrategyIndexChanges: {
      investmentStrategyIndexChangesByIdSelector,
    },
  },
} = selectors;

export const HistoricalPerformanceChart = (props) => {
  const { strategyId } = props;

  const [startDate, setStartDate] = useState(
    new Date().setFullYear(new Date().getFullYear() - 20)
  );

  const investmentStrategyIndexChangesByIds = useSelector(
    investmentStrategyIndexChangesByIdSelector(strategyId)
  );

  const chartData = investmentStrategyIndexChangesByIds.filter(
    (item) => new Date(item.changedAt) >= startDate
  );

  const yearlyData = getYearlyReturn(chartData);

  const positiveYears = yearlyData
    .filter((item) => item.change * 100 >= 0)
    .map((item) => item.year);

  const highestYearly = Math.max(...yearlyData.map((item) => item.change));

  return (
    <Container columns={6}>
      <Wrapper>
        <TitleWrapper>
          <H4>
            {t('presentation:investmentStrategy:historicalPerformance:title')}
          </H4>
          <TimeHorizonSelect
            simple={true}
            selectedCallback={(options) => {
              setStartDate(
                new Date().setFullYear(
                  new Date().getFullYear() - (options?.value || 20)
                )
              );
            }}
            style={{
              marginLeft: 'auto',
            }}
          />
          <ValueWrapper style={{ gap: SPACING_12 }}>
            <ValueWrapper>
              <ParagraphSmall>
                {t('advisor:strategySimulator:preferences:positiveYears')}
              </ParagraphSmall>
              <ParagraphSmall style={{ fontWeight: 500 }}>
                {positiveYears.length}
              </ParagraphSmall>
            </ValueWrapper>
            <Separator />
            <ValueWrapper>
              <ParagraphSmall>
                {t('wealthPlan:investmentStrategy:highestYearly')}
              </ParagraphSmall>
              <ParagraphSmall style={{ fontWeight: 500 }}>
                {percentage(highestYearly * 100, 1, false, false)}
              </ParagraphSmall>
            </ValueWrapper>
          </ValueWrapper>
        </TitleWrapper>
        <Row>
          <InvestmentStrategyAreaChart
            valueKey="change"
            formatLineData={(data) =>
              formatHistoricalPerformanceChartData(data)
            }
            chartData={chartData}
            domainPadding={{ x: [40, 0], y: [50, 30] }}
          />
        </Row>
      </Wrapper>
    </Container>
  );
};
