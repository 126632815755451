import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { H5 } from '../texts';
import {
  accent,
  backgroundWhite,
  hoverBoxShadowLvl1,
  hoverBoxShadowLvl2,
} from '../../constants/colors';
import {
  SPACING_16,
  SPACING_8,
  SPACING_12,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../constants/spacing';
import { mobileCondition } from '../../constants/media';
import { easeInOut, shortAnimationTime } from '../../constants/animation';
import { useClickOutside } from '../../services/hooks';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const Select = styled.div`
  border: 1px solid ${accent.ocean420};
  padding: ${SPACING_8} ${SPACING_16};
  border-radius: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_8};
  margin-left: auto;
  cursor: pointer;
  z-index: 1;
  width: ${(props) => `${props.width}px`};
  justify-content: space-between;
  max-width: 416px;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  &:hover {
    background: #eff2f7;
  }

  h5,
  p {
    color: ${(props) => props.textColor};
  }

  & > svg {
    transition: rotate ${easeInOut} ${shortAnimationTime};
    ${(props) =>
      props.position === 'top'
        ? css`
            rotate: ${(props) => (props.open ? '0deg' : '180deg')};
          `
        : css`
            rotate: ${(props) => (props.open ? '180deg' : '0deg')};
          `}
  }
  @media ${mobileCondition} {
    width: 100%;
    gap: ${SPACING_16};
  }
`;

const StyledSpriteIconFunctional = styled(SpriteIconFunctional)`
  margin-bottom: 1px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_8};
  white-space: nowrap;

  @media ${mobileCondition} {
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  min-width: 145px;
  background: ${backgroundWhite};
  border: 1px solid ${accent.ocean420};
  padding: ${SPACING_12} ${SPACING_8};
  position: absolute;
  right: 0;
  box-shadow: ${hoverBoxShadowLvl2};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  z-index: 2;
  gap: ${SPACING_8};
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.position === 'bottom'
      ? css`
          translate: 0 100%;
          bottom: -5px;
        `
      : css`
          top: -5px;
          translate: 0 -100%;
        `}

  @media ${mobileCondition} {
    width: 100%;
    box-shadow: ${hoverBoxShadowLvl1};
  }
`;

const Item = styled.div`
  padding: ${SPACING_8} ${SPACING_12};
  flex: 1;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  &:hover {
    background: #eff2f7;
  }

  @media ${mobileCondition} {
    padding: ${SPACING_16} 0;
  }
`;

export const SimpleSelectDropdown = (props) => {
  const {
    options = [],
    selectedItemKey,
    selectedCallback = () => {},
    position = 'bottom',
    color = accent.ocean490,
    defaultSelectedIndex = 0,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({});

  const containerRef = useRef();

  useClickOutside(containerRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  useEffect(() => {
    if (options.length) {
      if (selectedItemKey) {
        setSelected(options.filter(({ key }) => key === selectedItemKey)[0]);
      } else {
        // selects default item 0 by default or override with prop
        setSelected(options[defaultSelectedIndex]);
      }
    }
  }, []);

  useEffect(() => {
    selectedCallback(selected);
  }, [selected]);

  if (!options.length || !selected) return null;

  return (
    <Wrapper ref={containerRef} {...rest}>
      <Select
        onClick={() => setOpen(!open)}
        open={open}
        position={position}
        color={color}
        className="select"
      >
        <TextWrapper>
          <H5>{selected.label}</H5>
        </TextWrapper>

        <StyledSpriteIconFunctional
          id="Simple arrow"
          direction="Down"
          color={accent.ocean440}
          size={14}
        />
      </Select>
      {open ? (
        <ContentWrapper className="content" position={position}>
          {options.map((item) => {
            return (
              <Item
                onClick={() => {
                  setSelected(item);
                  setOpen(false);
                }}
                selected={selected}
                key={`select-dropdown-item-${item.value}`}
              >
                <H5>{item.itemLabel ? item.itemLabel : item.label}</H5>
              </Item>
            );
          })}
        </ContentWrapper>
      ) : null}
    </Wrapper>
  );
};
