import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { truncateString } from '@formue-app/core';

import {
  accent,
  backgroundWhite,
  hoverBoxShadowLvl1,
  hoverBoxShadowLvl2,
} from '../../constants/colors';
import {
  SPACING_24,
  SPACING_16,
  SPACING_8,
  SPACING_12,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../constants/spacing';
import { mobileCondition } from '../../constants/media';
import { H6LineHeight, H6Size } from '../../constants/text';

import { H3, Paragraph } from '../texts';
import { Toggle } from './Toggle';
import { StyledInputBase } from './Input';
import { SelectDropdownItem } from './SelectDropdownItem';

import { useClickOutside } from '../../services/hooks';

import ArrowDown from '../../assets/icons/arrows/arrow-down.svg';
import { OutlineSelectStyle, FilledSelectStyle } from './Select';

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const Select = styled.div`
  ${StyledInputBase};
  background-image: url(${ArrowDown});
  background-size: 11px 7px;
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 35px;
  cursor: pointer;
  max-width: 416px;
  width: ${(props) => (props.width ? `${props.width}px` : 'fit-content')};

  h5,
  p {
    color: ${(props) => props.textColor};
    line-height: 120%;
  }

  @media ${mobileCondition} {
    width: 100%;
    gap: ${SPACING_16};
  }

  ${(props) => props.inputstyle === 'filled' && FilledSelectStyle}
  ${(props) => props.inputstyle === 'outline' && OutlineSelectStyle}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_8};
  white-space: nowrap;

  @media ${mobileCondition} {
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  background: ${backgroundWhite};
  padding: ${SPACING_24};
  position: absolute;
  width: 416px;
  max-height: 600px;
  right: 0;
  box-shadow: ${hoverBoxShadowLvl2};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  z-index: 2;
  overflow-y: auto;

  ${(props) =>
    props.position === 'bottom'
      ? css`
          translate: 0 100%;
          bottom: -12px;
        `
      : css`
          top: -12px;
          translate: 0 -100%;
        `}

  @media ${mobileCondition} {
    width: 100%;
    box-shadow: ${hoverBoxShadowLvl1};

    & > h3 {
      text-transform: uppercase;
      font-size: ${H6Size};
      line-height: ${H6LineHeight};
    }
  }
`;

const Item = styled.div`
  padding: ${SPACING_8} 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  @media ${mobileCondition} {
    padding: ${SPACING_16} 0;
  }
`;

const Title = styled(Paragraph)`
  font-weight: 400;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: ${SPACING_8};
`;

const Footer = styled.div`
  border-top: 1px solid ${accent.sand1};
  padding-top: ${SPACING_16};
`;

const Header = styled.div`
  border-bottom: 1px solid ${accent.sand1};
  padding-bottom: ${SPACING_12};
  padding-top: ${SPACING_8};
`;

export const SelectDropdown = (props) => {
  const {
    options = [],
    title = 'Select',
    subtitle = 'Select view',
    width = 'auto',
    color = accent.ocean440,
    backgroundColor,
    textColor = accent.ocean490,
    position = 'bottom',
    icon,
    optionsIcon,
    selectMultiple,
    showAllText,
    selectedCallback = () => {},
    children,
    headerContent,
    optionsBorder,
    optionsPadding,
    small,
    inputstyle = 'outline',
    defaultSelectedIndex = 0,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const containerRef = useRef();

  useClickOutside(containerRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  useEffect(() => {
    if (options.length) {
      // selects all options by default
      if (showAllText) {
        setSelected([...options]);
      }
      // selects default item 0 by default or override with prop
      else {
        setSelected([options[defaultSelectedIndex]]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    selectedCallback(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  if (!options.length) return null;

  const Icon = icon ? icon(selected[0]) : null;

  return (
    <Wrapper ref={containerRef} {...rest}>
      <Select
        onClick={() => setOpen(!open)}
        open={open}
        position={position}
        color={color}
        backgroundColor={backgroundColor}
        textColor={textColor}
        className="select"
        width={width}
        inputstyle={inputstyle}
      >
        <IconWrapper>
          {Icon}

          <TextWrapper>
            <Paragraph style={{ lineHeight: '22px' }}>
              {truncateString(
                selectMultiple ? title : selected[0] && selected[0].label,
                28
              )}
            </Paragraph>
          </TextWrapper>
        </IconWrapper>
      </Select>
      {open ? (
        <ContentWrapper className="content" position={position}>
          <H3 style={{ paddingBottom: 4 }}>{subtitle}</H3>
          {showAllText && selectMultiple && (
            <Header>
              <Item
                className="item"
                key={`select-dropdown-show-all`}
                onClick={() => {
                  if (selected.length === options.length) {
                    setSelected([]);
                  } else {
                    setSelected([...options]);
                  }
                }}
              >
                <Title>{showAllText}</Title>
                <Toggle
                  id="select-dropdown-toggle-show-all"
                  name="select-dropdown-toggle-show-all"
                  value={selected.length === options.length}
                  onChange={() => {}}
                  small={small}
                />
              </Item>
              {headerContent && headerContent}
            </Header>
          )}

          {options.map((option, index) => {
            const Icon = optionsIcon ? optionsIcon(option) : null;
            return (
              <SelectDropdownItem
                option={option}
                value={option.value || index}
                icon={Icon}
                selectMultiple={selectMultiple}
                setSelected={setSelected}
                selected={selected}
                border={optionsBorder}
                padding={optionsPadding}
                small={small}
              />
            );
          })}
          {children && <Footer>{children}</Footer>}
        </ContentWrapper>
      ) : null}
    </Wrapper>
  );
};
