import createFlags from 'flag';
import { createReduxBindings } from 'flag/redux';
import { isEmpty } from 'lodash';
import { takeLatest, put, select } from 'redux-saga/effects';

/**
 * Define default flags here, these may be overwritten by dispatching the setFlagsAction
 * and passing flags in the payload. Which we can use to load flags config from the API
 * when needed. Features can also be computed properties, just pass in a function instead
 * of a boolean, number or string.
 *
 * https://github.com/garbles/flag
 */
const defaultFlags = {
  features: {},
  isLead: false,
  tester: (flags) => !isEmpty(flags.features) && !flags.isLead,
  showFlagSettings: (flags) => flags.tester,
};

const { FlagsProvider, Flag, useFlag, useFlags } = createFlags({});
const {
  setFlagsAction,
  getFlagsSelector,
  createFlagsReducer,
  ConnectedFlagsProvider,
} = createReduxBindings(FlagsProvider);

const reducer = createFlagsReducer(defaultFlags);

export const sagas = [
  function* resetFeatureFlags() {
    yield takeLatest('APP/FLUSH', function* () {
      // Make sure to flush all feature flags on app flush. We don't have a flush method
      // but we can go through all flags in the store and set the value to false, then
      // use that modifid flags list to set all to false
      const featureFlags = yield select((state) => state.flags.features);
      for (let flag in featureFlags) {
        featureFlags[flag] = false;
      }
      yield put(setFlagsAction({ features: featureFlags }));
    });
  },
];

export {
  reducer,
  Flag,
  useFlag,
  useFlags,
  setFlagsAction,
  getFlagsSelector,
  createFlagsReducer,
  ConnectedFlagsProvider,
};
