import { handleActions } from 'redux-actions';
import { actions } from './actions';

export { actions };
export { sagas } from './sagas';

const defaultState = {
  loaded: false,
  passkeysEnabled: true,
  registrationSuccess: undefined,
  registrationError: undefined,
};

export const reducer = handleActions(
  {
    [actions.loaded]: (state, { payload }) => ({
      ...state,
      passkeysEnabled: payload.passkeysEnabled,
      loaded: true,
    }),
    [actions.startRegistration]: (state) => ({
      ...state,
    }),
    [actions.finishRegistration]: (state) => ({
      ...state,
      registrationSuccess: true,
      registrationError: undefined,
    }),
    [actions.failedRegistration]: (state) => ({
      ...state,
      registrationSuccess: undefined,
      registrationError: true,
    }),
    [actions.failedAuthentication]: (state) => ({
      ...state,
    }),
    [actions.finishAuthentication]: (state) => ({
      ...state,
    }),
    [actions.storePasskeysEnabledFlag]: (state, { passkeysEnabled }) => ({
      ...state,
      passkeysEnabled,
    }),
  },
  defaultState
);
