import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  formatBigMoney,
  t,
  useResource,
  selectors,
  useErrorState,
} from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { SPACING_24, SPACING_8 } from '../../../constants/spacing';
import { H2, ParagraphSmall } from '../../texts';
import { WaterfallChart } from '../../graphs/WaterfallChart';
import { useDispatch, useSelector } from 'react-redux';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { accent } from '../../../constants/colors';
import {
  H6LineHeight,
  H6Size,
  paragraphXSmallLineHeight,
  paragraphXSmallSize,
} from '../../../constants/text';
import { printCondition } from '../../../constants/media';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};

  @media ${printCondition} {
    margin-left: ${SPACING_24} !important;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const StyledWaterfallChart = styled(WaterfallChart)`
  h6 {
    font-size: ${paragraphXSmallSize};
    line-height: ${paragraphXSmallLineHeight};
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    word-break: auto-phrase;

    width: 80%;
    margin-left: calc(-23px / 2);
  }

  h3 {
    font-size: ${H6Size};
    line-height: ${H6LineHeight};
    font-weight: 500;
    text-transform: none;
  }
`;

const {
  entities: {
    financialValues: { allfinancialValuesItemsSelector },
  },
} = selectors;

export const PeriodicChange = (props) => {
  const dispatch = useDispatch();

  // Flush data to prevent bug with data from different granularities mixing in store
  useEffect(() => {
    dispatch({ type: 'FINANCIALVALUES/FLUSH' });
  }, []);

  const loading = useResource([
    {
      type: 'FINANCIALVALUES/INDEX',
      args: { granularity: 'MONTH' },
    },
  ]);
  const error = useErrorState(['FINANCIALVALUES/INDEX']);
  const financialValues = useSelector(allfinancialValuesItemsSelector);

  const startPeriodData = financialValues[0];
  const currentPeriodData = financialValues[financialValues.length - 1];

  const totalGain = financialValues.reduce((acc, curr) => {
    return acc + curr?.gain;
  }, 0);
  const totalInOut = financialValues.reduce((acc, curr) => {
    return acc + (curr?.deposits + curr?.withdrawals);
  }, 0);

  const startMv =
    startPeriodData?.mv -
    startPeriodData?.gain -
    startPeriodData?.deposits -
    startPeriodData?.withdrawals;

  return (
    <StyledBaseSection columns={6}>
      <TextWrapper>
        <ParagraphSmall>
          {t('portfolio:periodicReporting:valueChange')}
        </ParagraphSmall>
        <H2>{formatBigMoney(currentPeriodData?.mv, false)}</H2>
      </TextWrapper>

      {loading ? (
        <CenteredActivityIndicator />
      ) : (
        <StyledWaterfallChart
          data={[
            {
              label: t(
                'presentation:annualReview:portfolioDevelopment:startValue'
              ),
              value: startMv,
              color: accent.ocean230,
            },
            {
              label: t(
                'presentation:annualReview:portfolioDevelopment:depositsWithdrawals'
              ),
              value: totalInOut,
              color: accent.ocean1,
            },
            {
              label: t('presentation:annualReview:portfolioDevelopment:return'),
              value: totalGain,
              color: accent.ocean370,
            },
          ]}
          total={{
            label: t('presentation:annualReview:portfolioDevelopment:endValue'),
            value: currentPeriodData?.mv,
            color: accent.ocean230,
          }}
          textColor={accent.ocean490}
          lineColor={accent.sand1}
        />
      )}
    </StyledBaseSection>
  );
};
