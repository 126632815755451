import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import camelcase from 'camelcase';
import { useParams, useHistory, useLocation } from 'react-router';
import smoothscroll from 'smoothscroll-polyfill';
import { useQueryState } from 'react-router-use-location-state';

import { clamp, useResource } from '@formue-app/core';

import {
  BaseSlide,
  AdminOverlay,
  ShareOverlay,
  ConfigureOverlay,
  PresentationHeader,
  PresentationFooter,
} from '../components/presentation';

import { useSlides } from '../components/presentation/slides/';

import { H1 } from '../components/texts';
import { accent, prinTextMain } from '../constants/colors';
import { PopoverBase } from '../components/popover/PopoverBase';
import { AnnualReviewPresentationMessages } from '../components/presentation/messages/AnnualReviewPresentationMessages';
import { InvestmentStrategyPresentationMessages } from '../components/presentation/messages/InvestmentStrategyPresentationMessages';
import { InvestmentStrategyExportPresentationMessages } from '../components/presentation/messages/InvestmentStrategyExportPresentationMessages';
import { CenteredActivityIndicator } from '../components/common/ActivityIndicator';
import { OperationalMessages } from '../components/operationalMessages/OperationalMessages';

const INVESTEMENT_STRATEGY_EXPORT_ID = 'investment-strategy-export';
const INVESTEMENT_STRATEGY_ID = 'investment-strategy';
const ANNUAL_REVIEW_ID = 'annual-review';

const SlidesWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: auto;
  background-color: ${accent.neutral4};

  // To accomodate for bad printers we need to up the text colors and increse the font size for the
  // smallest text on the strategy export
  ${(props) =>
    props.exportMode && props.id === INVESTEMENT_STRATEGY_EXPORT_ID
      ? css`
          * > h1,
          * > h2,
          * > h3,
          * > h4,
          * > p {
            color: ${prinTextMain};
          }

          * > p {
            font-size: 17px;
          }
        `
      : null}

  .slide,
  .details {
    scroll-snap-align: start;
  }

  // Fix for a bug where scrolling to the bottom of details would scroll the details back up
  // automaticly
  .details {
    scroll-margin-bottom: 1px;
  }

  &.snapScrollX {
    scroll-snap-type: x mandatory;
  }

  &.snapScrollY {
    scroll-snap-type: both mandatory;
  }
`;

// Fix for running buildt in smooth scroll on Safari, Edge and IE
smoothscroll.polyfill();

export const PresentationPage = (props) => {
  const { id } = useParams();
  const { hash } = useLocation();
  const history = useHistory();
  const [exportMode] = useQueryState('exportMode', false);

  const slides = useSlides()[camelcase(id)];

  const [slidesOrigin, setSlidesOrigin] = useState([]);
  const [activeSlides, setActiveSlides] = useState([]);
  const [activeSlide, setActiveSlide] = useState({});
  const [slidesLength, setSlidesLength] = useState(0);
  const [enabledSlides, setEnabledSlides] = useState([]);
  const [querySlides, setQuerySlides] = useState([]);
  const [activeIndex, setActiveIndex] = useState(parseInt(hash.slice(1)) || 0);

  useEffect(() => {
    const queryParams = new URLSearchParams(
      document.location.search.substring(1)
    );

    setQuerySlides(queryParams.getAll('slides').map((s) => Number(s)));
  }, []);

  useEffect(() => {
    // If we have passed slides in the query params we want to override the regular slides with our@
    // selected slides
    if (querySlides.length && slides.length) {
      setSlidesOrigin(
        slides.filter((item) =>
          querySlides.find((pageId) => pageId === item.id)
        )
      );
    } else if (slides.length) {
      setSlidesOrigin(slides);
    }
  }, [slides, querySlides]);

  useEffect(() => {
    setActiveSlides(slidesOrigin);
  }, [slidesOrigin]);

  useEffect(() => {
    // If we pass slides in the query params we want to ignore the fact that they are disabled by
    // default to ensure we show all the slides in export mode
    if (querySlides.length) {
      setEnabledSlides(activeSlides);
    } else {
      setEnabledSlides(activeSlides.filter((slide) => slide.enabled));
    }
  }, [activeSlides, querySlides]);

  useEffect(() => {
    if (enabledSlides) {
      setSlidesLength(enabledSlides.length - 1);
      setActiveSlide(enabledSlides[clamp(activeIndex, 0, slidesLength)]);
    }
  }, [enabledSlides, slidesLength, activeIndex]);

  const [showDetails, setShowDetails] = useState(false);
  const [autoScroll, setAutoScroll] = useState(false);
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const [showSharePanel, setShowSharePanel] = useState(false);
  const [showConfigurePanel, setShowConfigurePanel] = useState(false);
  const [alternativeBackground, setAlternativeBackground] = useState(false);
  const [wrapperNode, setwrapperNodeRef] = useState(null);

  const bodySelector = window.document.getElementsByTagName('body')[0];

  // Set the ref of the scroll container when the container is rendered
  const slidesWrapperRef = useCallback(
    (node) => {
      setwrapperNodeRef(node);
      setAutoScroll(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // For exportMode we want to disable transitions
  useEffect(() => {
    if (exportMode) {
      bodySelector.classList.add('notransition');
    } else {
      bodySelector.classList.remove('notransition');
    }
  }, [exportMode, bodySelector]);

  const handleScroll = useCallback(
    (e) => {
      // We don't want to hijack scrolling behaviour when in export mode
      if (exportMode) {
        return null;
      }

      // Handeling the different scroll states is grabbed more or less directly from here:
      // https://stackoverflow.com/questions/65952068/determine-if-a-snap-scroll-elements-snap-scrolling-event-is-complete
      const atSnappingPointX = e.target.scrollLeft % e.target.offsetWidth === 0;
      const atSnappingPointTopY = e.target.scrollTop === 0;
      const atSnappingPointDetailsY = e.target.scrollTop > 0;
      const timeOut = atSnappingPointX || atSnappingPointTopY ? 0 : 150;

      clearTimeout(e.target.scrollTimeout);

      if (showDetails) {
        if (e.target.scrollTop > e.target.clientHeight) {
          wrapperNode.classList.remove('snapScrollY');
        } else {
          wrapperNode.classList.add('snapScrollY');
        }
      }

      e.target.scrollTimeout = setTimeout(() => {
        if (!autoScroll) {
          if (atSnappingPointX && !showDetails) {
            // TODO: Fix slide jumping around in Safari
            // In Safari, at this point we sometimes trigger the next line
            // when we don't want to, and the activeIndex get's set to the
            // wrong thing which causes the presentation to jump to a random
            // slide.
            setActiveIndex(e.target.scrollLeft / e.target.offsetWidth);
          }

          if (atSnappingPointTopY && showDetails) {
            setShowDetails(false);
          }

          if (atSnappingPointDetailsY && !showDetails) {
            setShowDetails(true);
          }
        } else {
          if (atSnappingPointTopY && atSnappingPointX) {
            setAutoScroll(false);
            setShowDetails(false);
          }
        }
      }, timeOut);
    },
    [wrapperNode, showDetails, autoScroll, exportMode]
  );

  useEffect(() => {
    wrapperNode?.classList?.add('noScrollbar', 'snapScrollX');
    wrapperNode?.addEventListener('scroll', handleScroll);

    return () => {
      wrapperNode?.removeEventListener('scroll', handleScroll);
    };
  }, [wrapperNode, handleScroll]);

  // If the component has details we want to allow for snapping in the Y direction as well
  useEffect(() => {
    if (activeSlide) {
      if (activeSlide.detailsComponent) {
        wrapperNode?.classList?.add('snapScrollY');
      } else {
        wrapperNode?.classList?.remove('snapScrollY');
      }
    }
  }, [activeSlide, wrapperNode]);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      history.push(
        Object.assign(history.location, {
          hash: `#${activeIndex}`,
        })
      );
      if (autoScroll) {
        wrapperNode?.scroll({
          left: wrapperNode?.offsetWidth * activeIndex,
          top: showDetails ? wrapperNode?.offsetHeight : 0,
          behavior: exportMode ? 'auto' : 'smooth',
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, showDetails, wrapperNode]);

  // Set active index based on arrow key navigation
  useEffect(() => {
    const min = 0;
    const max = slidesLength;

    window.onkeydown = (e) => {
      switch (e.keyCode) {
        // Right
        case 39:
          setAutoScroll(true);
          setActiveIndex(clamp(activeIndex + 1, min, max));
          setShowDetails(false);
          break;
        // Left
        case 37:
          setAutoScroll(true);
          setActiveIndex(clamp(activeIndex - 1, min, max));
          setShowDetails(false);
          break;
        // Down
        case 40:
          setAutoScroll(true);
          activeSlide.detailsComponent && setShowDetails(true);
          break;
        // Up
        case 38:
          setAutoScroll(true);
          activeSlide.detailsComponent && setShowDetails(false);
          break;
        default:
          return null;
      }
    };
  });

  useEffect(() => {
    const defaultDimentions = {
      height: 900,
      width: 1440,
    };

    function handleResize() {
      const actuallDimentions = {
        height: window.innerHeight,
        width: window.innerWidth,
      };

      const ratio = {
        height: actuallDimentions.height / defaultDimentions.height,
        width: actuallDimentions.width / defaultDimentions.width,
      };

      // Calculate the optimal zoom level based on the default and
      // the actual dimentions of the screen
      // Unfortunetly this will also limit the manual zoom (ctrl +/-)
      document.body.style.zoom = Math.min(ratio.height, ratio.width);
    }

    // Call scale function initially to set zoom level
    handleResize();

    window.addEventListener('resize', () => {
      handleResize();

      // Only set index when actually scrolling, not on initial handleResize call
      setActiveIndex(Math.round(window.scrollX / window.innerWidth));
    });

    // Set the config overlay to be opened by default if the presentation is "Investment strategy"
    if (id === INVESTEMENT_STRATEGY_ID) {
      setShowConfigurePanel(true);
    }

    // returned function will be called on component unmount
    return () => {
      document.body.style.zoom = null;
      window.removeEventListener('resize', () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const investmentStrategiesLoading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(
      document.location.search.substring(1)
    );

    const enabledStrategies = queryParams.getAll('enabledStrategies');

    // If we have "enabledStrategies" in query parameters we want to take
    // the user straight to the presentation slides
    if (enabledStrategies.length) {
      setShowConfigurePanel(false);
    }
  }, []);

  if (enabledSlides.length === 0 || !activeSlide) return null;

  const { showPortfolioFilters, darkLogo } = activeSlide;

  // When we click on "Close presentation" button, we want to specify
  // the page where we get navigated to.
  let closePresentationLinkPath = '/';

  if (id === ANNUAL_REVIEW_ID) closePresentationLinkPath = '/advisor';
  if (id === INVESTEMENT_STRATEGY_ID)
    closePresentationLinkPath = '/advisor/strategy-simulator';

  // We want to avoid rendering Presentation slides before we have a chance
  // to choose strategies. That's the reason for this overlay to be rendered
  // differently than other overlays.
  if (showConfigurePanel) {
    return (
      <>
        <PresentationHeader
          darkLogo={true}
          activeSlide={{}}
          closePresentationLinkPath={closePresentationLinkPath}
        />
        <ConfigureOverlay
          slides={slidesOrigin}
          setActiveSlides={setActiveSlides}
          enabledSlides={enabledSlides}
          setShowConfigurePanel={setShowConfigurePanel}
        />
      </>
    );
  }

  if (id === INVESTEMENT_STRATEGY_ID && investmentStrategiesLoading) {
    return <CenteredActivityIndicator />;
  }

  return (
    <>
      <OperationalMessages />
      {showAdminPanel && (
        <AdminOverlay
          slides={slidesOrigin}
          enabledSlides={enabledSlides}
          setActiveSlides={setActiveSlides}
          setActiveIndex={setActiveIndex}
          setShowAdminPanel={setShowAdminPanel}
        />
      )}

      {showSharePanel && (
        <ShareOverlay
          slides={slidesOrigin}
          enabledSlides={enabledSlides}
          setShowSharePanel={setShowSharePanel}
        />
      )}

      <PresentationHeader
        showAdminPanel={showAdminPanel}
        setShowAdminPanel={setShowAdminPanel}
        showSharePanel={showSharePanel}
        setShowSharePanel={setShowSharePanel}
        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        activeSlide={activeSlide}
        showDetails={showDetails}
        showPortfolioFilters={showPortfolioFilters}
        alternativeColors={alternativeBackground}
        darkLogo={darkLogo}
        closePresentationLinkPath={closePresentationLinkPath}
        enableShare={id === ANNUAL_REVIEW_ID}
        style={{
          backgroundColor: showDetails
            ? alternativeBackground
              ? activeSlide.alternativeColors.backgroundColor
              : accent[activeSlide.backgroundColor]
            : null,
        }}
      />

      <SlidesWrapper
        id={id}
        exportMode={exportMode}
        ref={slidesWrapperRef}
        className="slides-wrapper"
        // Sort of a hackish solution to prevent the unwanted scroll on InvestmentStrategy presentation
        style={{
          overflowY:
            id === INVESTEMENT_STRATEGY_ID && !showDetails ? 'hidden' : 'auto',
        }}
      >
        {slidesLength < 0 ? (
          <H1>Ingen slides valgt</H1>
        ) : (
          enabledSlides.map((slide, index) => (
            <BaseSlide
              style={{
                backgroundColor: alternativeBackground
                  ? activeSlide.alternativeColors.backgroundColor
                  : accent[activeSlide.backgroundColor],
              }}
              key={`slide-${slide.id}`}
              setActiveIndex={setActiveIndex}
              setShowDetails={setShowDetails}
              activeIndex={activeIndex}
              slideIndex={index}
              isActiveSlide={activeIndex === index}
              setAlternativeBackground={setAlternativeBackground}
              presentationType={camelcase(id)}
              {...slide}
            />
          ))
        )}
        {!exportMode ? (
          <PresentationFooter
            setAutoScroll={setAutoScroll}
            setActiveIndex={setActiveIndex}
            setShowDetails={setShowDetails}
            showDetails={showDetails}
            activeIndex={activeIndex}
            activeSlide={activeSlide}
            slidesLength={slidesLength}
          />
        ) : null}
        <PopoverBase />
      </SlidesWrapper>
      {id === ANNUAL_REVIEW_ID ? <AnnualReviewPresentationMessages /> : null}

      {id === INVESTEMENT_STRATEGY_ID ? (
        <InvestmentStrategyPresentationMessages />
      ) : null}

      {id === INVESTEMENT_STRATEGY_EXPORT_ID ? (
        <InvestmentStrategyExportPresentationMessages />
      ) : null}
    </>
  );
};
