import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions,
  formatPeriodLabel,
  getLocalizedMonthName,
  t,
} from '@formue-app/core';

import { H4, ParagraphXSmall } from '../../texts';
import {
  accent,
  backgroundWhite,
  whiteTranslucent,
} from '../../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_8,
} from '../../../constants/spacing';

import { PeriodFilterModal } from './PeriodFilterModal';
import { SpriteIcon } from '../../common/SpriteIcon';

const {
  ui: { portfolio: uiPortfolioActions },
} = actions;

const StyledContainer = styled.div`
  display: flex;
  border-top: 1px solid #59497a;
`;

const StyledLabel = styled(H4)`
  color: ${backgroundWhite};
  font-weight: 400;
`;

const StyledButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  flex: 1;
  height: 63px;
  cursor: pointer;

  &:hover {
    background: ${whiteTranslucent(0.05)};
  }
`;

const FilterButton = (props) => {
  const {
    index,
    period,
    activePeriod,
    startDate,
    endDate,
    filteredPeriodValues,
    highlightColor,
    borderColor,
  } = props;

  const dispatch = useDispatch();

  const renderLabel = (period) => {
    const label = formatPeriodLabel(period, startDate, endDate, false, false);
    return (
      <StyledLabel style={{ fontWeight: period === activePeriod ? 500 : 400 }}>
        {label}
      </StyledLabel>
    );
  };

  return (
    <StyledButton
      key={index}
      style={{
        backgroundColor: period === activePeriod && highlightColor,
        borderBottom: period === activePeriod && `3px solid ${borderColor}`,
        borderBottomRightRadius:
          period === activePeriod &&
          index - 1 === filteredPeriodValues.length &&
          BORDER_RADIUS_LARGE,
        borderBottomLeftRadius:
          period === activePeriod && index === 0 && BORDER_RADIUS_LARGE,
      }}
      onClick={() => {
        dispatch(uiPortfolioActions.filterPeriod({ period }));
      }}
    >
      {renderLabel(period)}
    </StyledButton>
  );
};

export const PortfolioPeriodFilter = ({
  style,
  highlightColor = '#264990',
  borderColor = accent.ocean3,
  excludedPeriods = [],
}) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  const periodValues = useSelector(
    (state) => state.ui.portfolio.filterValues.period
  );

  const filteredPeriodValues = periodValues.filter(
    (period) => !excludedPeriods.includes(period)
  );

  const {
    period: activePeriod,
    startDate,
    endDate,
  } = useSelector((state) => state.ui.portfolio.filters);

  const onChange = (data) => {
    const [period, startDate, endDate] = data;

    dispatch(
      uiPortfolioActions.filterPeriod({
        period: period,
        startDate: startDate?.toISOString().split('T')[0],
        endDate: endDate?.toISOString().split('T')[0],
      })
    );
  };

  return (
    <>
      <StyledContainer style={{ ...style, ...{ borderColor: highlightColor } }}>
        {filteredPeriodValues.map((period, index) => (
          <FilterButton
            key={`filter-button-${index}`}
            index={index}
            period={period}
            activePeriod={activePeriod}
            startDate={startDate}
            endDate={endDate}
            filteredPeriodValues={filteredPeriodValues}
            highlightColor={highlightColor}
            borderColor={borderColor}
          />
        ))}
        <StyledButton
          onClick={() => setModalOpen(true)}
          style={{
            backgroundColor: activePeriod === 'CUSTOM' && highlightColor,
            borderBottom:
              activePeriod === 'CUSTOM' && `3px solid ${borderColor}`,
            borderBottomRightRadius: BORDER_RADIUS_LARGE,
          }}
        >
          {activePeriod === 'CUSTOM' ? (
            <>
              <ParagraphXSmall style={{ color: '#fff' }}>
                <SpriteIcon
                  id="Calendar"
                  size={SPACING_16}
                  inverted={true}
                  navigational={true}
                  style={{ marginRight: SPACING_8 }}
                />
                {`${getLocalizedMonthName(
                  new Date(startDate).getMonth(),
                  false
                )} ${new Date(startDate).getFullYear()} ${
                  endDate !== startDate
                    ? ` - ${getLocalizedMonthName(
                        new Date(endDate).getMonth(),
                        false
                      )} ${new Date(endDate).getFullYear()}`
                    : ''
                }`}
              </ParagraphXSmall>
            </>
          ) : (
            <StyledLabel>{t('filters:period:CUSTOM')}</StyledLabel>
          )}
        </StyledButton>
      </StyledContainer>
      <PeriodFilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onChange={onChange}
      />
    </>
  );
};
