import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { animated, useTransition, config } from 'react-spring';

import { selectors, t, localizeCountryName } from '@formue-app/core';

import { MainGrid } from '../../../layout';
import { BaseSection } from '../../../common/BaseSection';
import { SearchInput } from '../../../formElements';
import { Button } from '../../../buttons/Button';
import { H2 } from '../../../texts';
import { CountryItem } from './CountryItem';

import {
  SPACING_8,
  SPACING_24,
  SPACING_16,
} from '../../../../constants/spacing';
import {
  backgroundWhite,
  hoverBoxShadowLvl2,
  textMain,
} from '../../../../constants/colors';
import { easeInOut, shortAnimationTime } from '../../../../constants/animation';

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const StyledContainer = styled(BaseSection)`
  padding: 0;
  background-color: transparent;
`;

const StyledButton = styled(Button)`
  background-color: ${backgroundWhite};
  width: 100%;
  margin-top: ${SPACING_24};
  color: ${textMain};
  transition: box-shadow ${shortAnimationTime} ${easeInOut};

  &:hover {
    background-color: ${backgroundWhite};
    box-shadow: ${hoverBoxShadowLvl2};
  }
`;

const AnimatedCountryItem = animated(CountryItem);

const {
  entities: {
    securities: { securitiesByIdSelector, securitiesByCountrySelector },
  },
} = selectors;

export const CountryList = React.memo(
  ({ selectedContinent = 'Global', ...rest }) => {
    const byCountry = useSelector(securitiesByCountrySelector);
    const securitiesById = useSelector(securitiesByIdSelector);

    const [search, setSearch] = useState('');
    const [seeAll, setSeeAll] = useState(false);

    // reset list when switching continents
    useEffect(() => {
      setSeeAll(false);
    }, [selectedContinent]);

    const sortedCountries = Object.values(byCountry)
      .filter(({ countryCode, continent }) => {
        let searchResult = false;
        if (countryCode) {
          searchResult = search.length
            ? localizeCountryName(countryCode.toUpperCase())
                .toLowerCase()
                .includes(search.toLowerCase())
            : true;
        }

        return (
          (continent === selectedContinent && searchResult) ||
          (selectedContinent === 'Global' && searchResult)
        );
      })
      .sort((a, b) => b.partOfKnownPortfolio - a.partOfKnownPortfolio);

    const transitions = useTransition(sortedCountries, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      to: { opacity: 1 },
      trail: 80,
      reset: true,
      config: config.gentle,
    });

    return (
      <StyledContainer columns={12} {...rest}>
        <H2 style={{ marginBottom: SPACING_16, marginTop: SPACING_8 }}>
          {t('geography:countryAllocation')}
        </H2>
        <SearchInput
          key="search"
          name="search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder={t('geography:search')}
          className="filterItem"
          style={{ width: '100%', marginBottom: 20 }}
        />
        <StyledSubGrid>
          {transitions((style, country, key, index) => {
            if (index > 8 && !seeAll) return null;
            const sortedSecurities = country.securities.sort(
              (a, b) =>
                securitiesById[b].partOfKnownPortfolio -
                securitiesById[a].partOfKnownPortfolio
            );

            const topThree = sortedSecurities
              .filter((securityId, index) => index < 3)
              .map((securityId) => securitiesById[securityId]);

            return (
              <AnimatedCountryItem
                securities={topThree}
                countryCode={country.countryCode}
                partOfKnownPortfolio={country.partOfKnownPortfolio}
                columns={4}
                style={style}
                key={index}
              />
            );
          })}
        </StyledSubGrid>
        {!seeAll && sortedCountries.length > 3 && (
          <StyledButton
            onClick={() => {
              setSeeAll(true);
            }}
          >
            {t('globals:seeAll')}
          </StyledButton>
        )}
      </StyledContainer>
    );
  }
);
