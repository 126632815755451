import React from 'react';
import styled from 'styled-components';

import { percentage, selectors, t, useResource } from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { SPACING_24, SPACING_8 } from '../../../constants/spacing';
import { H2, ParagraphSmall } from '../../texts';
import { StretchedHorizontalBarChart } from '../../graphs/StretchedHorizontalBarChart';
import { DotIcon } from '../../common/DotIcon';
import { accent } from '../../../constants/colors';
import { useSelector } from 'react-redux';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { printCondition } from '../../../constants/media';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};

  @media ${printCondition} {
    margin-right: ${SPACING_24} !important;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const {
  entities: {
    portfolios: { portfolioTotalSelector },
    portfolioIndices: { allPortfolioIndicesSelector },
  },
} = selectors;

export const PeriodicBenchmark = (props) => {
  const loading = useResource(['PORTFOLIOS/INDEX', 'PORTFOLIO_INDICES/INDEX']);

  const portfolioTotal = useSelector(portfolioTotalSelector);
  const benchmarkItems = useSelector(allPortfolioIndicesSelector);

  const benchmarkData = benchmarkItems.map((item) => {
    return {
      icon: <DotIcon radius={20} color={accent.ocean190} />,
      label: item.name,
      value: item.rate,
      color: accent.ocean230,
      textColor: accent.ocean190,
    };
  });

  return (
    <StyledBaseSection columns={6}>
      <TextWrapper>
        <ParagraphSmall>
          {t('portfolio:periodicReporting:benchmark')}
        </ParagraphSmall>
        <H2>{percentage(portfolioTotal?.twr * 100, 1)}</H2>
      </TextWrapper>
      {!portfolioTotal || loading ? (
        <CenteredActivityIndicator />
      ) : (
        <StretchedHorizontalBarChart
          barWidth={30}
          data={benchmarkData}
          formatValue={(value) => percentage(value * 100, 1)}
        />
      )}
    </StyledBaseSection>
  );
};
