import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectors, useStartegyOptions } from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { StrategyList } from '../strategy/PortfolioStrategyList';
import { Paragraph } from '../../texts';
import { notPrintCondition, printCondition } from '../../../constants/media';
import MediaQuery from 'react-responsive';

const {
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

export const PeriodicStrategy = (props) => {
  const { endDate, period } = props;
  const activeStrategyId = useSelector(activeStrategySelector);
  const allStrategies = useStartegyOptions();

  let [activeStrategy, setActiveStrategy] = useState(null);

  useEffect(() => {
    setActiveStrategy(
      allStrategies.find((strategy) => strategy.id === activeStrategyId)
    );
  }, [allStrategies, activeStrategyId]);

  // if (
  //   period === 'CUSTOM' &&
  //   new Date(endDate).getMonth() !== new Date().getMonth()
  // ) {
  //   return (
  //     <BaseSection columns={4}>
  //       <Paragraph>
  //         You can only view strategy if last month in timeframe is same as
  //         current month
  //       </Paragraph>
  //     </BaseSection>
  //   );
  // }

  return (
    <>
      <MediaQuery query={notPrintCondition}>
        <BaseSection columns={4}>
          {activeStrategy ? (
            <StrategyList strategy={activeStrategy.strategy} />
          ) : (
            <Paragraph>Ingen strategi tilgjengelig</Paragraph>
          )}
        </BaseSection>
      </MediaQuery>
      <MediaQuery query={printCondition}>
        {activeStrategy ? (
          <StrategyList strategy={activeStrategy.strategy} />
        ) : (
          <Paragraph>Ingen strategi tilgjengelig</Paragraph>
        )}
      </MediaQuery>
    </>
  );
};
