import React, { useState } from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { GridElement } from '../../../../components/layout';
import { CollapsibleListItem } from '../../../../components/common/CollapsibleListItem';
import { H2, H3, H4, Paragraph } from '../../../../components/texts';
import { TableList } from '../../../../components/lists';
import { List, ListItem } from '../../../../components/common/List';

import { Toggle } from '../../../../components/formElements';
import { desktopCondition, tabletCondition } from '../../../../constants/media';
import {
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../../constants/spacing';
import { InfoCard } from '../../../../components/common/InfoCard';

const StyledInfoCard = styled(InfoCard)`
  margin-top: 10px;

  @media ${desktopCondition} {
    margin-right: 40px;
  }

  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const StyledIntroSection = styled(GridElement)`
  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
  max-width: 75%;
  padding: ${SPACING_24};
  padding-block: 0;
`;

const StyledList = styled(List)`
  margin: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 55px;
  margin-bottom: ${SPACING_16};
`;

export const GeneralPage = (props) => {
  const year = 2022;
  const [openAll, setOpenAll] = useState(false);

  const faqData = [
    {
      title: 'Tidspunkter for publisering av rapporter og oppgaver',
      details: (
        <TableList
          sortable={false}
          columns={[
            {
              key: 'title',
              label: 'Rapport/Publiseringstidspunkt',
              render: ({ title, publishDate }) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <H4>{title}</H4>
                  <Paragraph>{publishDate}</Paragraph>
                </div>
              ),
            },
            {
              key: 'type',
              label: 'Type',
            },
            { key: 'source', label: 'Kilde/sted' },
          ]}
          rows={[
            {
              title: 'Innskudd, utlån og rente m.v. (Formueskonto)',
              publishDate: 'Januar',
              type: 'Storebrand Nettbank',
              source: 'Storebrand Nettbank',
            },
            {
              title: 'Innskudd, utlån og rente m.v. 2022 (Kundekonto)',
              publishDate: 'Januar',
              type: 'Formue',
              source: 'Kundenett > Dokumenter',
            },
            {
              title: 'Deltakeroppgaver fra deltakerliknende selskaper (IS, DA)',
              publishDate: 'Januar - mai',
              type: 'Formue Forretningsførsel',
              source: 'Kundenett > Dokumenter',
            },
            {
              title:
                'VPS-årsoppgave for VPS-registrerte verdipapirfond, AS og obligasjoner',
              publishDate: 'Februar',
              type: 'Verdipapirsentralen (VPS)',
              source: 'VPS',
            },
            {
              title:
                'Deltakeroppgaver fra deltakerliknende selskaper (IS, DA, KS)',
              publishDate: 'Februar - mai',
              type: 'Eksterne forretningsførere',
              source: 'Eksterne forretningsførere',
            },
            {
              title: 'Skatterapport Nominee 2022 (Årsoppgave)',
              publishDate: 'Ca midten av februar',
              type: 'Formue',
              source: 'Kundenett > Dokumenter',
            },
            {
              title:
                'Fradragsberettigede honorarer og skattepliktige inntekter',
              publishDate: 'ca midten av mars',
              type: 'Formue',
              source: 'Kundenett > Skatt',
            },
          ]}
          keyExtractor={(item, index) => `faq-${index}`}
        />
      ),
    },
    {
      title: 'Frister for å sende inn skattemeldingen for 2022',
      details: (
        <ContentWrapper>
          <Paragraph>Frist for å levere skattemelding er:</Paragraph>
          <StyledList>
            <ListItem>30. april 2023 for personlig skattytere</ListItem>
            <ListItem>31. mai 2023 for næringsdrivende</ListItem>
          </StyledList>
          <Paragraph>
            Husk at du enkelt kan søke om utsettelse på Altinn. Gå til «nytt
            skjema» og søk «Søknad om utsatt frist for levering av
            skattemelding». Da kan du velge mellom skjema for lønnstakere og
            pensjonister (RF-1115), eller skjema for næringsdrivende (RF1114).
            Det innvilges automatisk én måneds utsettelse.
          </Paragraph>
          <Paragraph>
            Vi minner om at det for deltakere i selskapsformene ANS, DA, IS
            eller KS må levere skattemelding for næringsdrivende, selv om man
            har mottatt skattemelding for lønnstakere. Slik skattemelding finner
            du ved å logge inn i{' '}
            <a href="https://www.altinn.no" target="_blank" rel="noreferrer">
              Altinn
            </a>
            , velg deretter “nytt skjema”, søk etter RF-1030, og velg
            «Skattemelding for formue- og inntektsskatt – personlig
            næringsdrivende mv».
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Hva trenger jeg å fylle ut selv i skattemeldingen?',
      details: (
        <ContentWrapper>
          <Paragraph>
            <b>Fradragsberettigede honorarer og skattepliktige inntekter</b>
            <br />I år, som i fjor, finner du informasjon om fradragsberettigede
            honorarer digitalt her. Rapporten kan skrives ut og inneholder
            bekreftede fradragsberettigede honorarer og skattepliktige inntekter
            med informasjon om hvor i skattemeldingen du skal føre disse.
          </Paragraph>
          <Paragraph>Kommer i mars</Paragraph>
          <Paragraph>
            <b>
              Deltagere i selskap med Ansvarlig selskap (ANS), Delt Ansvar (DA),
              Indre Selskap (IS) eller Kommandittselskap (KS)
            </b>
            <br />
            Deltakere i ett eller flere selskap med Ansvarlig selskap (ANS),
            Delt Ansvar (DA), Indre Selskap (IS) eller Kommandittselskap (KS) må
            benytte skattemelding for næringsdrivende, selv om man har mottatt
            skattemelding for lønnstakere.
          </Paragraph>
          <Paragraph>
            Deltageroppgaver publiseres mellom januar og mai.
          </Paragraph>
          <Paragraph>
            Fremgangsmåte for levering via skatteetaten.no (Min skatt)
          </Paragraph>
          <ol>
            <li>
              Velg «Ønsker du å oppgi noen andre opplysninger?» nederst i
              skattemeldingen
            </li>
            <li>Velg «Formue og inntekt i selskap med deltakerfastsetting»</li>
            <li>
              Legg inn disse opplysningene fra den mottatte deltakeroppgaven
              (RF-1221):
              <ul>
                <li>Navn på selskapet</li>
                <li>Organisasjonsnummer på selskapet</li>
                <li>
                  Andel netto formue/andel negativ netto formue (ref. post 1101)
                </li>
                <li>
                  Ev. gevinst/tap ved realisasjon av andel (ref. post 1145)
                </li>
              </ul>
            </li>
            <li>
              Klikk deretter på «Legg til næringstype» og velg næringstype
              «Annen næring»
            </li>
            <li>
              Legg inn disse beløpene fra deltakeroppgaven;
              <ul>
                <li>
                  Ev. alminnelig inntekt/negativ alminnelig inntekt (ref. post
                  1140)
                </li>
                <li>Ev. tillegg i alminnelig inntekt (ref. post 1143)</li>
              </ul>
            </li>
            <li>Trykk deretter Ok</li>
          </ol>

          <Paragraph>
            <b>Skattepliktig formue og fradragsberettiget gjeld</b>
            <br />
            Hvis du har private fordringer eller lån, f.eks. mellomværende med
            eget aksjeselskap, må du føre opp beløpet som skattepliktig formue
            eller fradragsberettiget gjeld i skattemeldingen din.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Hva er allerede fylt ut for meg i skattemeldingen?',
      details: (
        <ContentWrapper>
          <Paragraph>
            I skattemeldingen for 2022 er det viktig at du kontrollerer:
          </Paragraph>
          <StyledList>
            <ListItem>Alle forhåndsutfylte opplysninger </ListItem>
            <ListItem>Om noen opplysninger mangler</ListItem>
          </StyledList>
          <div>
            <H3>Formue</H3>
            <Paragraph>
              Vi rapporterer følgende opplysninger til skattemyndighetene:
            </Paragraph>
          </div>
          <StyledList>
            <ListItem>
              Aksjesparekonto, Verdipapirfond og Notes hos Formue finner du i
              dokumentet <b>Skatterapport Nominee 2022</b> i dokumentarkivet
            </ListItem>
            <ListItem>
              Oversikt over bankinnskudd og renter hos Formue finner du i
              dokumentet <b>Innskudd, utlån og rente m.v. 2022</b> i
              dokumentarkivet
            </ListItem>
          </StyledList>
          <div>
            <H3>Formueskonto </H3>
            <Paragraph>
              Formueskontoen rapporteres av Storebrand. Saldo- og renteoppgave
              finner du ved å logge deg inn på 
              <a
                href="https://nettbank.storebrand.no"
                target="_blank"
                rel="noreferrer"
              >
                https://nettbank.storebrand.no
              </a>
              .
            </Paragraph>
          </div>
          <div>
            <H3>VPS-konto</H3>
            <Paragraph>
              Hvis du har verdipapirkonto hos VPS finner du årsoppgave for
              VPS-kontoen ved å logge på VPS Investortjenester via 
              <a href="https://www.vps.no/" target="_blank" rel="noreferrer">
                https://www.vps.no
              </a>
              .
            </Paragraph>
          </div>
          <Paragraph>
            Pålogging kan skje med BankID/BankID på mobil, alternativt med
            brukernavn/passord fra VPS. Dersom du har glemt ditt
            påloggingspassord kan dette bestilles på samme side. Vi gjør
            oppmerksom på at det kun er privatpersoner som kan bestille nytt
            passord elektronisk og logge på med BankID/BankID på mobil. For
            bedriftskunder må tilsendt passord benyttes sammen med org.nr.
          </Paragraph>
          <Paragraph>
            For bestilling av nytt passord, send e-post til 
            <a href="mailto:kundenett@formue.no">kundenett@formue.no</a>.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Tilgang til kundenett for regnskapsførere eller andre',
      details: (
        <ContentWrapper>
          <Paragraph>
            Dersom du ønsker å gi tilgang til regnskapsfører eller andre
            personer kan du kontakte din rådgiver eller sende oss en mail på
            <a href="mailto:kundeservice@formue.no">kundeservice@formue.no</a>.
          </Paragraph>
          <Paragraph>
            Vi må få oppgitt navn, fødsels- og personnummer, telefonnummer og
            adresse på den som skal ha lesetilgang. Vi vil deretter legge ut et
            skjema på kundenettet som signaturberettiget må signere.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title:
        'Hvor finner jeg informasjon om den skattemessige behandlingen av de ulike verdipapirene mine hos Formue?',
      details: (
        <ContentWrapper>
          <Paragraph>
            Under finner du informasjon om den skattemessige behandlingen av de
            ulike verdipapirene dine hos Formue.
          </Paragraph>
          <Paragraph>
            <a
              href="https://formue.my.salesforce.com/sfc/p/0Y000000pG6P/a/07000000Thp0/yYK3DxDZofAhdOKGE.abj2tu.AiGJHA_lRqjGL4Srag"
              target="_blank"
              rel="noreferrer"
            >
              Skattemessige behandlingen
            </a>
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Hvor finner jeg  formuesverdier  på aksjer for 2022?',
      details: (
        <ContentWrapper>
          <Paragraph>
            Alle aksjeselskap tilrettelagt av Formue AS med forretningsførsel
            hos Formue Accounting AS rapporteres gjennom VPS og godkjente
            formuesverdier finnes i Årsoppgaven fra VPS for 2022. Dette må
            sammenholdes med opplysninger i RF-1088.
          </Paragraph>
          <Paragraph>
            Aksjeselskap og andre juridiske innretninger (ikke privatpersoner)
            som skal benytte verdi 31.12.2022, kan henvende seg til{' '}
            <a href="mailto:accounting@formue.no">accounting@formue.no</a> og få
            tilsendt verdien direkte.
          </Paragraph>
        </ContentWrapper>
      ),
    },
  ];

  return (
    <>
      <StyledInfoCard
        title="Fradragsberettigede honorarer og skattepliktige inntekter 2022"
        body="For å se dine fradragsberettigede honorarer og skattepliktige inntekter har vi laget en oversikt over dette på linken nedenfor."
        navLabel="Se oversikt"
        to={`/portfolio/reporting/tax/declaration/${year}`}
      />
      <StyledIntroSection columns={5} style={{ marginTop: SPACING_8 }}>
        <H3 style={{ marginBottom: SPACING_8 }}>Viktig for privatpersoner</H3>
        <Paragraph style={{ marginBottom: 35 }}>
          Vi minner om at lønnsmottakere og pensjonister mottar sin
          skattemelding i Min skatt, hvor opplysningene er inndelt etter temaer
          (ikke poster som tidligere).
        </Paragraph>
        <H3 style={{ marginBottom: SPACING_8 }}>
          Overføring til Aksjesparekonto
        </H3>
        <Paragraph>
          Har du gjennomført overføring fra Aksjesparekonto hos annen leverandør
          til Aksjesparekonto hos Formue i 2022? Vi ber deg kontrollere
          overførselen på skattemeldingen din. Skulle du oppdage noe som ikke
          stemmer ber vi deg kontakte avgivende leverandør for å rette feilen.
        </Paragraph>
      </StyledIntroSection>
      <GridElement columns={12}>
        <TitleWrapper>
          <H2>Ofte stilte spørsmål</H2>
          <Toggle
            label="Se alle"
            value={openAll}
            onChange={() => setOpenAll(!openAll)}
          />
        </TitleWrapper>
        {faqData.map((q, index) => (
          <CollapsibleListItem
            details={q.details}
            detailsStyle={q.detailsStyle}
            startExpanded={openAll}
            style={{ marginTop: SPACING_8, padding: SPACING_24 }}
            key={`faq-${camelcase(q.title)}-${index}`}
          >
            <H3 style={{ marginRight: 'auto' }}>{q.title}</H3>
          </CollapsibleListItem>
        ))}
      </GridElement>
    </>
  );
};
