import { useSelector, useDispatch } from 'react-redux';
import { generateLoadingStateKey } from '../store/api/reducer';

export const useResource = (actions = []) => {
  const dispatch = useDispatch();
  /**
   * Loop through all actions, and fetch its loading state by doing a
   * useSelector call for that action spesifically. We also need to
   * check if the object "state.api.loading" has a value for the action
   * key. It will not if the resource/action has not been loaded yet, in
   * those cases we default to saying that the resource is loading. It
   * might not be yet if we haven't dispatched an action to start loading.
   *
   * A previous version of this hooks used a single useSelector call and
   * fetched the loading object itself, e.g:
   * `useSelector((state) => state.api.loading)` but that would cause a
   * re-render anytime _any_ resource's loading state changed, and not
   * just when the state of the actions we pass into this hook changed.
   *
   * React Hooks should not be called inside loops or callbacks because
   * React needs to know the order hooks are called to optimize the way
   * it renders stuff. In this case, the benefit of a loop is so high
   * that as long as we can be pretty sure the loop iterates in the same
   * order everytime it should be safe. In most cases we actually call
   * this hook with just one item, and at most a few, and we also manually
   * build the list of actions so it should never change.
   *
   * We are also doing this inside a custom hook, wich supposedly makes it
   * better, since it will use the hooks return value to determine if it
   * needs to re-render, not the hooks inside this hook, or something to
   * that effect.
   *
   * https://reactjs.org/docs/hooks-rules.html#explanation
   *
   * If this causes problems down the line, we need to refactor this hook
   * to only allow one action as the input, and instead of doing:
   *
   * ```
   * const loading = useResource(['FOO/INDEX', 'BAR_INDEX']);
   *
   * ...
   *
   * if (loading) {...}
   * ```
   *
   * We would need to do something like:
   *
   * ```
   * const fooLoading = useResource('FOO/INDEX');
   * const barLoading = useResource('BAR/INDEX');
   * const loading = fooLoading || barLoading;
   *
   * if (loading) {...}
   * ```
   * When sending in args to an action we need to make sure to pass them along to the
   * indexRequestSuccess action on compleatin to access the loading key
   *
   * ```
   * const loading = useResource([{
   *  type: 'FOO/INDEX',
   *  args: args,
   *}]);
   *
   * if (loading) {...}
   *
   * …
   *
   * actions.indexRequestSuccess({ ...normalized, args: payload.args })
   *
   * ```
   */
  const result = actions.map((action) => {
    // Action can either by a string, or an object containing the action name
    // and arguments. So it can look like 'FOO/INDEX', or { type: 'FOO/INDEX', args: {...}}
    let actionType;
    let args;

    const isObject = typeof action === 'object' && action !== null;
    if (isObject) {
      actionType = action.type;
      args = action.args;
    } else {
      actionType = action;
    }

    const loadingStateKey = generateLoadingStateKey(actionType, args);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSelector((state) => {
      if (state.api.loading.hasOwnProperty(loadingStateKey)) {
        return state.api.loading[loadingStateKey];
      } else {
        dispatch({ type: `${actionType}_REQUEST_BEGIN`, payload: { args } });
        return true;
      }
    });
  });

  return result.some((r) => Boolean(r));
};

export const useErrorState = (actions = []) => {
  // See comment about calling hooks in loops above
  const result = actions.map((action) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSelector((state) => state.api.error[action]);
  });

  return result.some((r) => Boolean(r));
};
