import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Modal } from '../common/Modal';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_24,
  SPACING_32,
  SPACING_4,
  SPACING_48,
  SPACING_8,
} from '../../constants/spacing';
import { H2, H3, H4, Paragraph, ParagraphSmall } from '../texts';
import { ButtonRound, ButtonSmall } from '../buttons';
import { humanFriendlyDate, selectors, t } from '@formue-app/core';
import { useSelector } from 'react-redux';
import { accent } from '../../constants/colors';
import { SpriteIcon } from '../common/SpriteIcon';
import { ContactCardSimple } from '../common/ContactCardSimple';

const BtuttonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${SPACING_24};
  margin-bottom: ${SPACING_32};
  gap: 8px;
`;

const StyledButtonRound = styled(ButtonRound)`
  ${(props) =>
    props.active &&
    css`
      background: ${accent.ocean230};
    `}
`;

const MeetingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_32};
`;

const Meeting = styled.div`
  display: flex;
  flex-direction: column;
  background: ${accent.sand150};
  padding: ${SPACING_24};
  border-radius: ${SPACING_12};
  gap: ${SPACING_24};
`;

const DateWrapper = styled(Paragraph)`
  font-weight: 500;
  padding: ${SPACING_4} ${SPACING_8};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background: ${accent.sand1};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
  align-items: flex-start;
`;

const Content = styled.div`
  display: flex;
  gap: ${SPACING_16};
`;

const LocationWrapper = styled.div`
  flex: 1;
`;

const ParticipantsWrapper = styled.div`
  flex: 3;
`;

const Participants = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${SPACING_8};
  row-gap: ${SPACING_24};
`;

const StyledContactCardSimple = styled(ContactCardSimple)`
  display: flex;
  flex-basis: calc(50% - ${SPACING_4});
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const {
  entities: {
    meetings: { upcomingMeetingsSelector, pastMeetingsSelector },
  },
} = selectors;

const PastMeeting = ({ meeting }) => {
  return (
    <Meeting key={meeting.id}>
      <Header>
        <TitleWrapper style={{ gap: SPACING_8 }}>
          <H3 style={{ color: accent.ocean470 }}>{meeting.name}</H3>
          <ParagraphSmall style={{ color: accent.ocean470 }}>
            {humanFriendlyDate(meeting.startAt, true)}
          </ParagraphSmall>
        </TitleWrapper>
      </Header>
    </Meeting>
  );
};

const UpcomingMeeting = ({ meeting }) => {
  return (
    <Meeting key={meeting.id}>
      <Header>
        <TitleWrapper>
          <H2>{meeting.name}</H2>
          <DateWrapper>{humanFriendlyDate(meeting.startAt, true)}</DateWrapper>
        </TitleWrapper>
        <ButtonWrapper>
          <ButtonSmall
            buttonstyle="tonal"
            icon={
              <SpriteIcon
                id="Calendar folded"
                navigational={true}
                size={SPACING_16}
              />
            }
            download
            external={true}
            to={meeting.icsLink}
          >
            {t('eventCalendar:meetings:addToCalendar')}
          </ButtonSmall>
          {meeting.teamsMeetingLink ? (
            <ButtonSmall
              buttonstyle="tonal"
              external={true}
              to={meeting.teamsMeetingLink}
            >
              {t('eventCalendar:meetings:joinOnTeams')}
            </ButtonSmall>
          ) : null}
        </ButtonWrapper>
      </Header>
      <Content>
        {meeting.location.streetAddress ? (
          <LocationWrapper>
            <H4 style={{ fontWeight: 500, marginBottom: SPACING_8 }}>
              {t('eventCalendar:meetings:place')}
            </H4>
            <Paragraph>{meeting.location.streetAddress}</Paragraph>
            <Paragraph>
              {meeting.location.city} {meeting.location.zipCode}
            </Paragraph>
          </LocationWrapper>
        ) : null}

        {meeting?.participants?.length ? (
          <ParticipantsWrapper>
            <H4 style={{ fontWeight: 500, marginBottom: SPACING_8 }}>
              {t('eventCalendar:meetings:participants')}
            </H4>
            <Participants>
              {meeting.participants.map((item) => (
                <StyledContactCardSimple contact={item} />
              ))}
            </Participants>
          </ParticipantsWrapper>
        ) : null}
      </Content>
    </Meeting>
  );
};

export const MeetingsModal = (props) => {
  const { modalOpen, setModalOpen } = props;

  const upcomingMeetings = useSelector(upcomingMeetingsSelector);
  const pastMeetings = useSelector(pastMeetingsSelector);

  const [meetingsTimeframe, setMeetingsTimeframe] = useState('upcoming');
  const [meetings, setMeetings] = useState(upcomingMeetings);

  useEffect(() => {
    if (modalOpen) setMeetingsTimeframe('upcoming');
  }, [modalOpen]);

  useEffect(() => {
    if (meetingsTimeframe === 'upcoming') {
      setMeetings(upcomingMeetings);
    } else {
      setMeetings(pastMeetings);
    }
  }, [meetingsTimeframe, pastMeetings, upcomingMeetings]);

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      contentWidth={830}
      padding={SPACING_48}
    >
      <H2>{t('eventCalendar:meetings:title')}</H2>
      <BtuttonWrapper>
        <StyledButtonRound
          buttonstyle="outline"
          onClick={() => setMeetingsTimeframe('upcoming')}
          active={meetingsTimeframe === 'upcoming'}
        >
          {t('eventCalendar:meetings:upcomingMeetings')}
        </StyledButtonRound>
        <StyledButtonRound
          buttonstyle="outline"
          onClick={() => setMeetingsTimeframe('past')}
          active={meetingsTimeframe === 'past'}
          disabled={pastMeetings.length === 0}
        >
          {t('eventCalendar:meetings:previousMeetings')}
        </StyledButtonRound>
      </BtuttonWrapper>
      <MeetingsWrapper>
        {meetings.map((meeting, index) => {
          return meetingsTimeframe === 'upcoming' ? (
            <UpcomingMeeting key={`meeting-${index}`} meeting={meeting} />
          ) : (
            <PastMeeting key={`meeting-${index}`} meeting={meeting} />
          );
        })}
      </MeetingsWrapper>
    </Modal>
  );
};
