import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { selectors, t } from '@formue-app/core';

import { accent, textLightest } from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_64,
  SPACING_16,
} from '../../../constants/spacing';

import { H1 } from '../../texts';
import { StrategyList } from '../../portfolio/strategy/PortfolioStrategyList';
import { slideAnimationDelay } from '../../../constants/animation';

import { StrategyPicker } from '../../portfolio/strategy/StrategyPicker';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const StyledStrategyPicker = styled(StrategyPicker)`
  margin-bottom: ${SPACING_64};
  position: absolute;
  right: 0;
  top: 0;
`;

const {
  entities: {
    strategy: { allStrategiesSelector },
  },
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

export const PortfolioStrategySlide = (props) => {
  const { isActiveSlide } = props;
  const [activeStrategy, setActiveStrategy] = useState(null);
  const [numInside, setNumInside] = useState(0);
  const [numOutside, setNumOutside] = useState(0);

  const allStrategies = useSelector(allStrategiesSelector);
  const activeStrategyId = useSelector(activeStrategySelector);

  useEffect(() => {
    setActiveStrategy(
      allStrategies.find((strategy) => strategy.id === activeStrategyId)
    );
  }, [allStrategies, activeStrategyId]);

  useEffect(() => {
    let inside = 0;
    let outside = 0;

    if (activeStrategy) {
      for (const strategy of activeStrategy.strategy) {
        const max = strategy.upperLimit;
        const min = strategy.lowerLimit;
        const strategyShare = strategy.strategyShare;
        const market = strategy.marketShare;

        if (strategyShare === 0) {
          continue;
        }

        const width = max - min;
        const currentPosition = market === 0 ? 0 : 50 - strategyShare + market;
        const limitStart = 50 - (strategyShare - min);
        const limitEnd = limitStart + width;

        if (currentPosition >= limitStart && currentPosition <= limitEnd) {
          inside++;
        } else {
          outside++;
        }
      }

      setNumInside(inside);
      setNumOutside(outside);
    }
  }, [activeStrategy]);

  return (
    <Wrapper>
      <ContentWrapper>
        <H1
          style={{
            color: accent.velvet2,
            marginBottom: SPACING_16,
            textTransform: 'lowercase',
          }}
        >
          <Trans
            i18nKey={t('presentation:annualReview:portfolioStrategy:inside', {
              num: numInside,
            })}
            components={{
              strong: (
                <span style={{ color: accent.velvet3, fontWeight: 400 }} />
              ),
            }}
          />
          <br />
          <Trans
            i18nKey={t('presentation:annualReview:portfolioStrategy:outside', {
              num: numOutside,
            })}
            components={{
              strong: (
                <span style={{ color: accent.velvet3, fontWeight: 400 }} />
              ),
            }}
          />
        </H1>
        <StyledStrategyPicker />
      </ContentWrapper>
      <ContentWrapper>
        {activeStrategy ? (
          <StrategyList
            strategy={activeStrategy.strategy}
            runAnimation={isActiveSlide}
            customAnimationDelay={slideAnimationDelay}
            showOverviewDetails={false}
            textColor={textLightest}
            barColor="rgba(245,243,240, 0.1)"
            barInsideColor={accent.velvet3}
            barOutsideColor="#6D4594"
            light={true}
          />
        ) : null}
      </ContentWrapper>
    </Wrapper>
  );
};
