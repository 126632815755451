import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ContentWrapper, MainGrid } from '../../components/layout';
import { PopoverBase } from '../../components/popover/PopoverBase';
import { BasePage } from './BasePage';

import { PageTitle } from '../../components/common/PageTitle';
import { ProfileBar } from '../../components/common/ProfileBar';
import { BackButton } from '../../components/common/BackButton';

import { mobileCondition, PrintWrapper } from '../../constants/media';
import {
  menuClosedContentMaxWitdh,
  layoutMaxWitdh,
  menuMaxWidth,
  menuClosedWidth,
} from '../../constants/grid';
import { Logo } from '../../components/common/Logo';
import { SPACING_32, SPACING_24, SPACING_88 } from '../../constants/spacing';
import { AdvisorProfileBar } from '../../components/advisorsCorner/AdvisorProfileBar';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../components/common/ErrorFallback';

const StyledMainGrid = styled(MainGrid)`
  margin-bottom: 70px;

  ${(props) =>
    !props.menuOpen &&
    `
    max-width: ${menuClosedContentMaxWitdh}px;`}

  @media ${mobileCondition} {
    margin-top: 70px; // Height of mobile menu bar
  }
`;

const CenterContent = styled.div`
  max-width: ${layoutMaxWitdh}px;
  flex: 1;

  ${(props) =>
    !props.menuOpen &&
    `
    max-width: ${layoutMaxWitdh + menuMaxWidth - menuClosedWidth}px;`}
`;

const Header = styled(ContentWrapper)`
  min-height: auto;
  align-items: center;
  position: absolute;
  width: 100%;
`;

export const SimplePage = (props) => {
  const {
    children,
    title,
    subTitle,
    gridTemplate,
    subpage = false,
    icon,
    size,
    routes = [],
    location,
    titleStyles,
    showIcon,
    hideHeaderForPrint = false,
  } = props;
  const { mainMenuOpen } = useSelector((state) => state.ui.navigation);

  const isEmployee = useSelector((state) => state.auth.isEmployee);

  const subPageProps = routes.find((item) => item.path === location.pathname);

  return (
    <BasePage {...props}>
      <Header>
        <Logo
          style={{ marginLeft: SPACING_24 }}
          monochrome={false}
          height="17"
        />
        {isEmployee ? <AdvisorProfileBar /> : null}
      </Header>
      <ContentWrapper
        style={{ justifyContent: 'center', paddingTop: SPACING_88 }}
      >
        <CenterContent menuOpen={mainMenuOpen}>
          <StyledMainGrid menuOpen={mainMenuOpen} gridTemplate={gridTemplate}>
            <ErrorBoundary
              FallbackComponent={(props) =>
                ErrorFallback({ ...props, showBackButton: false })
              }
              showBackButton={false}
            >
              {!isEmployee ? <ProfileBar columns={12} /> : null}

              <PrintWrapper>
                <Logo
                  style={{ verticalAlign: 'bottom', marginBottom: SPACING_32 }}
                  monochrome={false}
                  height="24"
                />
              </PrintWrapper>

              <PageTitle
                columns={9}
                subTitle={subTitle}
                title={title}
                icon={icon}
                size={size}
                subPageProps={subPageProps}
                style={titleStyles}
                showIcon={showIcon}
                hideHeaderForPrint={hideHeaderForPrint}
              />
              {subpage && <BackButton columns={12} />}

              {children}
            </ErrorBoundary>
          </StyledMainGrid>
        </CenterContent>
        <PopoverBase />
      </ContentWrapper>
    </BasePage>
  );
};
