import React from 'react';
import styled from 'styled-components';

import { accent } from '../../constants/colors';
import { HorizontalBarChart } from './HorizontalBarChart';
import { ParagraphSmall } from '../texts';
import { SPACING_12, SPACING_8 } from '../../constants/spacing';

const Wrapper = styled.div`
  position: relative;
`;

const Legend = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
`;

const LegendItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.paddingVertical}px ${SPACING_12};
  height: ${(props) => props.height + props.paddingVertical * 2}px;
`;

const IconWrapper = styled.div`
  display: flex;
  gap: ${SPACING_8};
`;

export const StretchedHorizontalBarChart = (props) => {
  const {
    data,
    barWidth = 28,
    barPadding = 4,
    color = accent.ocean330,
    textColor = accent.ocean490,
    formatValue = (value) => value,
    ...rest
  } = props;

  return (
    <Wrapper {...rest}>
      <HorizontalBarChart
        // HorizontalBarChart expects colors to be set on a "data entry level"
        // If that's not the case, we want some default color to appear
        data={data.map((item) => ({ color, ...item }))}
        renderLabel={false}
        scale={1}
        headroom={0}
        barWidth={barWidth}
        barPadding={barPadding}
        allEdgesRounded={true}
      />
      <Legend>
        {data.map(({ label, value, icon }) => (
          <LegendItem
            height={barWidth}
            paddingVertical={barPadding}
            key={`item-${value}`}
          >
            <IconWrapper>
              {icon && icon}
              <ParagraphSmall style={{ color: textColor }}>
                {label}
              </ParagraphSmall>
            </IconWrapper>
            <ParagraphSmall style={{ fontWeight: 500, color: textColor }}>
              {formatValue(value)}
            </ParagraphSmall>
          </LegendItem>
        ))}
      </Legend>
    </Wrapper>
  );
};
