import React, { useState, useRef, useEffect } from 'react';
import { maxBy, minBy } from 'lodash';
import { VictoryArea } from 'victory';
import styled from 'styled-components';

import {
  t,
  selectors,
  filterSustainabilityTimelineChartData,
  percentage,
  getSustainabilityTrend,
  toLocaleDateString,
  localizeFloat,
} from '@formue-app/core';
import { accent } from '../../../../constants/colors';
import { BaseSection } from '../../../common/BaseSection';
import { H2, H2Small, Paragraph } from '../../../texts';
import { PeriodFilterDropdown } from './PeriodFilterDropdown';
import { TrendBadge } from './TrendBadge';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_32,
} from '../../../../constants/spacing';
import { useSize } from '../../../../services/hooks';
import { useSelector } from 'react-redux';

const {
  entities: {
    portfolioLenses: { carbonFootprintTimelineSelector },
  },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_32};
  position: relative;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
`;

const ChartWrapper = styled.div`
  transition: opacity 0.3s ease-in-out;
`;

const StyledPeriodFilterDropdown = styled(PeriodFilterDropdown)`
  position: absolute;
  right: ${SPACING_32};
  top: ${SPACING_32};
  margin-right: 0 !important;
`;

const DateAxis = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: ${SPACING_12};
`;

const Date = styled(Paragraph)``;

export const CarbonFootprintChart = (props) => {
  const [chartWidth, setChartWidth] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState('TWELVEMONTHS');

  const wrapperRef = useRef(null);
  const size = useSize(wrapperRef);
  const { width } = size;
  const carbonFootprintTimeline = useSelector(carbonFootprintTimelineSelector);
  const chartData = filterSustainabilityTimelineChartData(
    carbonFootprintTimeline,
    selectedPeriod
  );

  useEffect(() => {
    setChartWidth(width);
  }, [width]);

  const maxValue = maxBy(carbonFootprintTimeline, 'value');
  const minValue = minBy(carbonFootprintTimeline, 'value');

  if (!minValue || !maxValue) return null;

  const y0 = minValue.value;
  const trend = getSustainabilityTrend(
    'carbonFootprint.carbonFootprint',
    selectedPeriod
  );
  const percentageTrend = getSustainabilityTrend(
    'carbonFootprint.carbonFootprint',
    selectedPeriod,
    true
  );

  return (
    <StyledBaseSection {...props}>
      <H2Small style={{ paddingBottom: 8 }}>
        {t('portfolio:development')}
      </H2Small>
      <StyledPeriodFilterDropdown
        selectedCallback={(selected) => setSelectedPeriod(selected)}
      />
      <TrendBadge
        title={t('sustainability:tonsOfCo2', {
          value: localizeFloat(trend.toFixed(1)),
        })}
        trend={trend}
        style={{ paddingBottom: 24 }}
        positiveTrend={trend < 0}
        direction={trend > 0 ? 'Up' : 'Down'}
      />
      <svg style={{ height: 0, display: 'none' }}>
        <defs>
          <linearGradient id="myGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={accent.ocean230} />
            <stop offset="20%" stopColor="transparent" />
          </linearGradient>
        </defs>
      </svg>
      <ChartWrapper ref={wrapperRef}>
        <svg
          viewBox={'0 0' + ' ' + chartWidth + ' ' + '130'}
          preserveAspectRatio="none"
          width="100%"
        >
          <VictoryArea
            animate={false}
            standalone={false}
            width={chartWidth}
            height={130}
            padding={{ bottom: 20, left: 20 }}
            domainPadding={{ x: [60, 0], y: 5 }}
            y="value"
            y0={(d) => y0}
            data={chartData}
            interpolation="monotoneX"
            style={{
              data: {
                stroke: accent.ocean490,
                strokeWidth: 3,
                fill: 'url(#myGradient)',
              },
            }}
          />
        </svg>
      </ChartWrapper>
      <DateAxis>
        <Date>
          {toLocaleDateString(chartData[0].date, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </Date>
        <Date>
          {toLocaleDateString(chartData[chartData.length - 1].date, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </Date>
      </DateAxis>
    </StyledBaseSection>
  );
};
