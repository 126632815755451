import React from 'react';
import styled from 'styled-components';
import { printCondition, PrintWrapper } from '../../constants/media';

const StyledPrintPageBreaker = styled.div`
  @media ${printCondition} {
    page-break-before: always;
  }
`;

export const PrintPageBreaker = () => {
  return (
    <PrintWrapper>
      <StyledPrintPageBreaker />
    </PrintWrapper>
  );
};
