import React from 'react';
import styled from 'styled-components';

import { H4, H6 } from '../../texts';
import { accent, backgroundWhite } from '../../../constants/colors';
import { SPACING_8 } from '../../../constants/spacing';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const StyledDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: ${SPACING_8};
`;

const StyledTitle = styled(H6)`
  color: ${accent.velvet2};
  margin: 0 10px;
`;

const StyledValue = styled(H4)`
  color: ${backgroundWhite};
`;

export const AssetClassGroup = ({ title, value, color, ...rest }) => {
  return (
    <StyledContainer {...rest}>
      <StyledDot style={{ backgroundColor: color }} />
      <StyledTitle>{title}</StyledTitle>
      <StyledValue>{value}</StyledValue>
    </StyledContainer>
  );
};
