import React from 'react';
import { NavLink as RouteNavLink } from 'react-router-dom';
import styled from 'styled-components';

import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
  SPACING_8,
} from '../../constants/spacing';
import { accent, backgroundWhite } from '../../constants/colors';
import { easeInOut, shortAnimationTime } from '../../constants/animation';
import { desktopCondition, notDesktopCondition } from '../../constants/media';

import { StyledH5 } from '../texts';

const StyledNavLink = styled(RouteNavLink)`
  position: relative;
  display: inline-flex;
  width: 160px;
  min-width: 110px;
  padding: ${SPACING_16};
  gap: ${SPACING_8};
  justify-content: space-between;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background-color: ${accent.sand1};
  text-decoration: none;
  background-color: ${backgroundWhite};
  border: 1px solid ${accent.sand370};
  cursor: pointer;
  transition: background-color ${shortAnimationTime} ${easeInOut};

  &:hover {
    background-color: ${accent.sand370};
  }

  @media ${desktopCondition} {
    &:last-child {
      margin-right: 0;
    }
  }

  @media ${notDesktopCondition} {
    flex: 1;
  }

  &.active {
    background-color: ${(props) => props.color};
    color: ${backgroundWhite};
    border-color: ${(props) => props.color};
  }

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.3;
    `}
`;

const StyledText = styled(StyledH5)`
  ${(props) => props.isActive && `color: ${backgroundWhite}`}
`;

export const SubPageNavigationItem = (props) => {
  const { title, path, icon, showInMenu = true, disabled, color } = props;
  const isActive = window.location.pathname === path;

  if (!showInMenu) return null;

  const handleClick = (e) => {
    if (disabled) e.preventDefault();
  };

  return (
    <StyledNavLink
      exact={true}
      to={path}
      activeClassName="active"
      disabled={disabled}
      onClick={handleClick}
      color={color}
    >
      {icon}
      <StyledText isActive={isActive}>{title}</StyledText>
    </StyledNavLink>
  );
};
