import React from 'react';
import styled, { css } from 'styled-components';
import { StyledGridElement } from '../layout/GridElement';

import { accent, backgroundWhite } from '../../constants/colors';
import {
  mobileCondition,
  tabletCondition,
  printCondition,
} from '../../constants/media';
import {
  SPACING_48,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../constants/spacing';

export const baseSectionStyles = css`
  background: ${backgroundWhite};
  border-radius: ${SPACING_8};
  padding: ${SPACING_24};

  @media ${mobileCondition} {
    padding: ${SPACING_16};
  }

  @media ${printCondition} {
    padding: ${SPACING_16} !important;
    border-top: 2px solid ${accent.sand3};
    border-radius: 0;
  }
`;

export const StyledBaseSection = styled(StyledGridElement)`
  ${baseSectionStyles}
`;

export const StyledPaddedBaseSection = styled(StyledBaseSection)`
  padding: ${SPACING_48};

  @media ${tabletCondition} {
    padding: ${SPACING_24};
  }

  @media ${mobileCondition} {
    padding: ${SPACING_16};
  }
`;

export const BaseSection = (props) => {
  const { children, style } = props;

  return (
    <StyledBaseSection as="section" {...props} style={style}>
      {children}
    </StyledBaseSection>
  );
};

export const PaddedBaseSection = (props) => {
  const { children, style } = props;

  return (
    <StyledPaddedBaseSection {...props} style={style}>
      {children}
    </StyledPaddedBaseSection>
  );
};
