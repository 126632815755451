export function formatMoney(value, sufix = '', prefix) {
  // We don't want to show 0 if we don't have data, so instead
  // return a string indicating that we are missing data.
  if (!value && value !== 0) {
    return '-';
  }

  let formattedValue = Math.round(value)
    .toString()
    .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
    .concat(` ${sufix}`);

  if (prefix) {
    if (parseFloat(formattedValue) < 0) {
      return `- ${formattedValue.substr(1)}`;
    }
    if (parseFloat(formattedValue) > 0) {
      return `+ ${formattedValue}`;
    }
  }

  return formattedValue.replaceAll(' ', ' ').trimEnd();
}

export const formatBigMoney = (value, prefix = true) => {
  // We don't want to show 0 if we don't have data, so instead
  // return a string indicating that we are missing data.
  if (!value && value !== 0) {
    return '-';
  }

  let formattedValue;
  const fixedValue = value.toFixed(0);
  switch (true) {
    case Math.abs(fixedValue) > 999999: {
      formattedValue = `${(value / 1000000)
        .toFixed(1)
        .toString()
        .replace('.', ',')} MKR`;
      break;
    }
    case Math.abs(fixedValue) > 999: {
      formattedValue = `${value
        .toFixed(0)
        .toString()
        .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} KR`;
      break;
    }
    case fixedValue === 0: {
      formattedValue = '0 KR';
      break;
    }
    default: {
      formattedValue = `${value
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} KR`;
      break;
    }
  }

  if (prefix) {
    if (parseFloat(formattedValue) < 0) {
      return `- ${formattedValue.substr(1)}`;
    }
    if (parseFloat(formattedValue) > 0) {
      return `+ ${formattedValue}`;
    }
    return formattedValue;
  }

  return formattedValue.replaceAll(' ', ' ').trimEnd();
};
