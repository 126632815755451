import CryptoJS from 'crypto-js';

import {
  generateCodeChallenge,
  generateRandomAuthStateString,
} from './utilities';

import { config } from '../../config';
import { getUserAgent } from '../../api/utils/api';

export const registerUser = async (payload, codeVerifier) => {
  const codeChallenge = generateCodeChallenge(codeVerifier);
  const randomStateString = generateRandomAuthStateString();

  const username = config.api.client.user;
  const password = config.api.client.pass;

  const wordArray = CryptoJS.enc.Utf8.parse(`${username}:${password}`);
  const base64AuthHeader = CryptoJS.enc.Base64.stringify(wordArray);

  const data = {
    ...payload,
    response_type: 'code',
    client_id: config.api.client.user,
    state: randomStateString,
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
  };

  try {
    const result = await fetch(`${config.api.host}/auth/register`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'User-Agent': getUserAgent(),
        'X-User-Agent': getUserAgent(),
        Authorization: `Basic ${base64AuthHeader}`,
      },
    }).then((response) => response.json());
    return result;
  } catch (error) {
    console.error('Error during registration:', error);
  }
};
