import * as accounts from './accounts';
import * as accountTeamMembers from './accountTeamMembers';
import * as articles from './articles';
import * as authenticatorDevices from './authenticatorDevices';
import * as cashFlowEvents from './cashFlowEvents';
import * as clients from './clients';
import * as costReports from './costReports';
import * as documents from './documents';
import * as esgData from './esgData';
import * as events from './events';
import * as expertInputs from './expertInputs';
import * as familyAndOwnership from './familyAndOwnership';
import * as feed from './feed';
import * as filterValues from './filterValues';
import * as financialValues from './financialValues';
import * as forms from './forms';
import * as funds from './funds';
import * as goals from './goals';
import * as illiquidCashflow from './illiquidCashflow';
import * as illiquidSecurities from './illiquidSecurities';
import * as investmentProfile from './investmentProfile';
import * as investmentStrategySimulations from './investmentStrategySimulations';
import * as investmentStrategies from './investmentStrategies';
import * as investmentStrategyAssetAllocations from './investmentStrategyAssetAllocations';
import * as investmentStrategyImplementationPlans from './investmentStrategyImplementationPlans';
import * as investmentStrategyIndexChanges from './investmentStrategyIndexChanges';
import * as investmentStrategyPortfolioCharacteristics from './investmentStrategyPortfolioCharacteristics';
import * as investmentStrategyProducts from './investmentStrategyProducts';
import * as investmentStrategyCosts from './investmentStrategyCosts';
import * as marketValues from './marketValues';
import * as meetings from './meetings';
import * as offices from './offices';
import * as operationalMessages from './operationalMessages';
import * as personalGreeting from './personalGreeting';
import * as podcastEpisodes from './podcastEpisodes';
import * as portfolioIndices from './portfolioIndices';
import * as portfolioLenses from './portfolioLenses';
import * as portfolios from './portfolios';
import * as securities from './securities';
import * as securityPerformance from './securityPerformance';
import * as signingEvents from './signingEvents';
import * as strategy from './strategy';
import * as strategySimulator from './strategySimulator';
import * as tasks from './tasks';
import * as taxDetails from './taxDetails';
import * as tradeOrders from './tradeOrders';
import * as transactions from './transactions';
import * as users from './users';
import * as wealthBalance from './wealthBalance';
import * as webinars from './webinars';

export const selectors = {
  accounts,
  accountTeamMembers,
  articles,
  authenticatorDevices,
  cashFlowEvents,
  clients,
  costReports,
  documents,
  esgData,
  events,
  expertInputs,
  familyAndOwnership,
  feed,
  filterValues,
  financialValues,
  forms,
  funds,
  goals,
  illiquidCashflow,
  illiquidSecurities,
  investmentProfile,
  investmentStrategySimulations,
  investmentStrategies,
  investmentStrategyAssetAllocations,
  investmentStrategyImplementationPlans,
  investmentStrategyIndexChanges,
  investmentStrategyPortfolioCharacteristics,
  investmentStrategyProducts,
  investmentStrategyCosts,
  marketValues,
  meetings,
  offices,
  operationalMessages,
  personalGreeting,
  podcastEpisodes,
  portfolioIndices,
  portfolioLenses,
  portfolios,
  securities,
  securityPerformance,
  signingEvents,
  strategy,
  strategySimulator,
  tasks,
  taxDetails,
  tradeOrders,
  transactions,
  users,
  wealthBalance,
  webinars,
};
