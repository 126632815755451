// finds the n's NEXT nearest GCD (greatest comon divisor) of the v
// example outputs
// (1001, 100) => 1100
// (1001, 50) => 1050
// (923, 10) => 930
// (13, 2) => 14
export const nearest = (n, v) => {
  n = n / v;
  n = (n < 0 ? Math.floor(n) : Math.ceil(n)) * v;
  return n;
};

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

// finds the index of the closest value given the array of values such as like [10, 20, 30, 40, 50]
// example outputs
// (24, [10, 20, 30, 40, 50]) => 20
export const findClosest = function (x, arr) {
  var indexArr = arr.map(function (k) {
    return Math.abs(k - x);
  });
  var min = Math.min.apply(Math, indexArr);
  return arr[indexArr.indexOf(min)];
};

export const closestPowerOfTen = (num) =>
  Math.pow(10, Math.floor(Math.log10(num)));

export const round = (num, decimalPlaces = 0) => {
  if (num < 0) {
    return -round(-num, decimalPlaces);
  }
  const p = Math.pow(10, decimalPlaces);
  const n = (num * p).toPrecision(15);
  return Math.round(n) / p;
};

export const sum = (values) => {
  return values.reduce((accumulator, value) => accumulator + value, 0);
};

export const average = (values) => {
  return sum(values) / values.length;
};
