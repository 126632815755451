import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { GridElement } from '../layout/GridElement';

import {
  mobileCondition,
  tabletCondition,
  printCondition,
  PrintWrapper,
} from '../../constants/media';
import { H1, H2 } from '../../components/texts';
import { SPACING_24, SPACING_16, SPACING_8 } from '../../constants/spacing';
import { accent } from '../../constants/colors';
import { SpriteIcon } from './SpriteIcon';

const StyledPageTitle = styled(GridElement)`
  padding: ${SPACING_24} 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  z-index: 2;
  gap: ${SPACING_24};

  @media ${mobileCondition} {
    align-items: center;
    grid-column: span 10;
  }

  @media ${printCondition} {
    padding: 0;
    margin-bottom: ${SPACING_16};
    display: ${(props) => (props.hideOnPrint ? 'none' : 'unset')};
  }

  @media ${mobileCondition} {
    padding: ${SPACING_8} 0;
    margin-top: ${SPACING_8};
  }
`;

const StyledSpan = styled.span`
  @media ${printCondition} {
    display: none;
  }
`;

const StyledSpriteIcon = styled(SpriteIcon)`
  margin-top: 2px;

  @media ${tabletCondition} {
    margin-right: ${SPACING_16};
  }

  @media ${mobileCondition} {
    width: ${SPACING_24};
    height: ${SPACING_24};
  }

  @media ${printCondition} {
    display: none;
  }
`;

const StyledSubTitle = styled(H2)`
  color: ${accent.ocean4};
  opacity: 0.7;
  margin-top: ${SPACING_8};
`;

export const PageTitle = (props) => {
  const {
    title,
    subTitle,
    icon,
    size,
    subPageProps = {},
    showIcon = true,
    hideHeaderForPrint = false,
    ...rest
  } = props;
  const {
    showMobileTitle = true,
    title: subRoutTitle,
    hideHeaderForPrint: subRoutHideHeaderForPrint = false,
  } = subPageProps;

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  const hideOnPrint = hideHeaderForPrint || subRoutHideHeaderForPrint;

  if (mobileSize && !showMobileTitle) {
    return null;
  }

  if (!title) {
    return <GridElement {...rest} style={{ height: 112 }} />;
  }

  return (
    <StyledPageTitle hideOnPrint={hideOnPrint} {...rest}>
      {icon && showIcon ? <StyledSpriteIcon id={icon} size={48} /> : null}
      <StyledSpan>
        <H1>{title}</H1>
        {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
      </StyledSpan>

      <PrintWrapper>
        <H2>{subRoutTitle}</H2>
        {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
      </PrintWrapper>
    </StyledPageTitle>
  );
};
