import React from 'react';
import styled from 'styled-components';

import { printCondition } from '../../constants/media';

const PageNumberContainer = styled.div`
  /* @media ${printCondition} {
    @page :blank {
      @top-center {
        content: 'This page is intentionally left blank.';
      }
    }
  } */
`;

const PageNumber = styled.div`
  @media ${printCondition} {
    display: table-footer-group;

    &:after {
      counter-increment: page;
      content: 'Side ' counter(page);
    }
  }
`;

export const PrintPageNumbers = (props) => {
  const { children } = props;

  return <PageNumberContainer />;
};
