import { groupBy } from './arrays';
import { average } from './math';
import { getAssetClassGroupByAssetClass } from './portfolio';
import { orderBy } from 'lodash';

// Generate month range string
export const monthRange = (i, monthsPerRound) => {
  if (monthsPerRound > 1) {
    return `${monthsPerRound * i + 1} - ${monthsPerRound * i + monthsPerRound}`;
  } else {
    return monthsPerRound * i + 1;
  }
};

export const formatInvestmentStrategyImplementationPlanData = (strategy) => {
  const result = strategy?.assetClasses?.reduce((acc, assetClassObj) => {
    const assetGroup = getAssetClassGroupByAssetClass(assetClassObj.assetClass);

    const existingGroup = acc.find((item) => item.assetGroup === assetGroup);

    if (existingGroup) {
      existingGroup.sharePerRound = existingGroup.sharePerRound.map(
        (value, index) => value + assetClassObj.sharePerRound[index]
      );
      existingGroup.target += assetClassObj.target;
    } else {
      acc.push({
        assetGroup,
        sharePerRound: [...assetClassObj.sharePerRound],
        target: assetClassObj.target,
      });
    }

    return acc;
  }, []);

  return orderBy(result, 'assetGroup', 'asc');
};

export const aggregateYearlyData = (data) => {
  const yearlyData = data?.reduce((acc, current) => {
    const currentDate = new Date(current.changedAt);
    const currentYear = currentDate.getFullYear();

    const existingYear = acc.find((item) => item.year === currentYear);

    if (existingYear) {
      existingYear.change += current.change;
    } else {
      acc.push({
        year: currentYear,
        change: current.change,
      });
    }

    return acc;
  }, []);

  return yearlyData;
};

export const getHistoricalPerformancePositiveYears = (data) => {
  const yearlyData = aggregateYearlyData(data);
  const positiveYears = yearlyData.filter((year) => year.change > 0);

  return positiveYears;
};

export const getHistoricalDrawdownNegativeYears = (data) => {
  const yearlyData = aggregateYearlyData(data);
  const positiveYears = yearlyData.filter((year) => year.change < 0);

  return positiveYears;
};

export const getYearlyReturn = (indexChanges) => {
  const groupedByYear = groupBy(indexChanges, (item) =>
    new Date(item.changedAt).getFullYear()
  );
  const yearlyReturns = [];
  Object.keys(groupedByYear).forEach((year) => {
    const changes = groupedByYear[year].map((values) => values.change);
    const annualAverage = changes.reduce((sum, a) => sum + a, 0);
    yearlyReturns.push({ year, change: annualAverage });
  });

  return yearlyReturns;
};
